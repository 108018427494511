
import { getPageCode } from '../../Components/HelperFunctions/NumberFunctions';
import {similarity} from '../../Components/HelperFunctions/SearchAlgo'

let initialState = {
    cached_response:{},pageResponse:[],paginationLength:0,loadingTests:true
}

function formatString(str)
{
    str = str.replace(/[^a-zA-Z ]/g, "");
    str = str.toLowerCase();
    return str;
}


const getSearchedData = (pageResponse,SearchData) => {
    let tempSelectedItems = [];
        
    pageResponse?.map((el) => 
        {
            if(
            (
                SearchData=="" ||
                ((similarity(el.subjectName,SearchData)>0.7 ) || (similarity(el.gradeName,SearchData)>0.7 ) || 
                (similarity(el.title,SearchData)>0.7 ))
                ||
                (formatString(el.subjectName).includes(formatString(SearchData)) ||
                formatString(el.gradeName).includes(formatString(SearchData)) ||
                formatString(el.title).includes(formatString(SearchData)))
            ))
            {
                tempSelectedItems.push(el);
            }
        }
    );
    console.log('Searched Data ',tempSelectedItems.length)
    return tempSelectedItems;
    
}

const AllTestsReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_ALL_TESTS':
            {
                let payload = action.payload;
                //console.log(payload)
                let code = getPageCode(payload);
                if(payload.paginationQuery)
               {
                    state.cached_response[code]=payload; //cache
                    let dummy = payload.response.recordsTotal;
                    if(payload.response.recordsFiltered > payload.response.recordsTotal){
                        dummy = payload.response.recordsFiltered;
                    }
                    state.paginationLength = Math.ceil(dummy/ payload.paginationQuery.pageSize); //current_view
               }
                // if(action.payload.SearchData!=undefined && action.payload.SearchData.length!=0 && action.payload.SearchData!=null)
                // {
                //     state.pageResponse = getSearchedData(action.payload.response,action.payload.SearchData)
                // }
                // else 
                state.pageResponse = payload.response.data;
                state.loadingTests=false;
                return {...state};
            }
        case 'GET_ALL_TESTS_CACHED_BY_PAGE':
            {
                let code = getPageCode(action.payload);
                {
                    let pageResponseFromCache = state.cached_response[code];
                    //Return cached Response
                    state.pageResponse = pageResponseFromCache.response.data;
                    let dummy = pageResponseFromCache.response.recordsTotal;
                    if(pageResponseFromCache.response.recordsFiltered > pageResponseFromCache.response.recordsTotal){
                        dummy = pageResponseFromCache.response.recordsFiltered;
                    }
                    state.paginationLength = Math.ceil(dummy / pageResponseFromCache.paginationQuery.pageSize); //current_view
                }
                state.loadingTests=false;
                return {...state};
            }
        case 'RESET_TO_ALL_TESTS':
            {
                state.pageResponse = state.cached_response['1000'].response.data;
                state.loadingTests=false;
                return {...state};
            }
        case 'SET_LOADING_TESTS':
            {
                state.loadingTests=true;
                return {...state};
            }
           
        default:
             return state;
    }
}

export default AllTestsReducer;