let initialState = []

const GetGradesByLangReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_GRADES_BYLANG':
            {
                state=action.payload;
                return [...state];
            }

        default:
             return state;
    }
}

export default GetGradesByLangReducer;