import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function SendStudentTeacherInvitationAPI(action)
{
    const requestParams = action.payload;
    let http = `${ApiData.BaseApiLink}/teacher/SendStudentInvitation?studentId=${requestParams.studentId}&&TeacherId=${getCookie('domockexamID')}`;
    console.log(' SendStudentTeacherInvitationAPI : ',http);
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        console.log('Invite sent successfully to students parent ',res.data)
        return res.data;
    })
    .catch((err)=>{
        console.log('err-SendStudentTeacherInvitationAPI:',err);
    })
}

function* SendStudentTeacherInvitationAPIWorkerSaga(action)
{
    try{
            const Result = yield call(SendStudentTeacherInvitationAPI,action);
            console.log('SEND_STUDENT_TEACHER_INVITATION',Result);
            yield put({type:'SEND_STUDENT_TEACHER_INVITATION',payload:Result})
    }
    catch(e)
    {

    }
}

export function* SendStudentTeacherInvitationAPISaga()
{
    yield takeEvery('SEND_STUDENT_TEACHER_INVITATION_REQUESTED',SendStudentTeacherInvitationAPIWorkerSaga)
}