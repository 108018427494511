

let initialState = {result:undefined}


const RegisterReducer = (state=initialState,action) => {
   
    switch(action.type)
    {
        
        case 'ERROR_REGISTER_USER':
            {
                state = action.payload;
                return {...state};
            }
        case 'REGISTER_USER':
            {
                state = action.payload;
                return {...state};
            }
        case 'CLEAR_REGISTER_USER':
            {
                state=initialState;
                return {...state};
            }
        default:
             return {...state};
    }
}

export default RegisterReducer;