import React, { useEffect, useState , useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TestCard from './TestCard';
import Popup from '../HelperComps/Popup';
import { Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import './Exams.css'
import comingsoon1 from '../../images/comingsoon1.jpg';
import { CustomDropDown } from '../HelperComps/FancyDropdown';
import Pagination from './Pagination';
import {ScrollToTop} from '../HelperFunctions/ScrollToTop'
import { getPageCode } from '../HelperFunctions/NumberFunctions';

function AllTests(props) {

    const {selectedData,subjectListColors,gradeListColors,testType} = props;
    const dispatch = useDispatch();
    const AllTestsState = useSelector(state => state.AllTestsReducer)
    const SearchData = useSelector(state => state.SearchReducer)
    const [showModal, setshowModal] = useState(false);
    const UserLogin = useSelector(state => state.LoginReducer);
    const [studentId, setStudentId] = useState([]); //UserLogin?.childrenData[0]?.id
    const AssignedTest = useSelector(state => state.SaveStudentTestReducer);
    const numberOfTestsPerPage = 50;
    const PaginationRef = useRef();
    let payload = {};

    useEffect(() => {
        {
            if(AssignedTest.status==true)
            {
                setshowModal(true);
                dispatch({type:'RESET_STUDENT_TEST'});
            }
        }
    }, [AssignedTest]);

    const createPayloadHandler =  (pageNumber) => {
        dispatch({type:'SET_LOADING_TESTS'});
        
        payload={
                "testId":0,//All tests
                "gradeId": selectedData[1]?.backendId!=undefined?selectedData[1]?.backendId:0,
                "subjectId": selectedData[2]?.backendId!=undefined?selectedData[2]?.backendId:0,
                "languageId": selectedData[0]?.backendId!=undefined?selectedData[0]?.backendId:0,
                "studentId": 0,
                "SearchData":SearchData,
                "paginationQuery": {
                  "pageNumber": pageNumber,
                  "pageSize": numberOfTestsPerPage,
                  "pageCount": 0
                },
                "isTutorviewOnly": UserLogin.userType=='Teacher' && testType=='myTests'
              }
        
        let code = getPageCode(payload);
        if(AllTestsState.cached_response[code]!=null && SearchData=='')  
        {
            dispatch({type:'GET_ALL_TESTS_CACHED_BY_PAGE',payload:payload});
        }
        else 
        {
            dispatch({type:'GET_ALL_TESTS_REQUESTED',payload:payload});
        }
        ScrollToTop();
    }

    useEffect(() => {
        createPayloadHandler(1);
        if(UserLogin.userType == 'Parent' || UserLogin.userType == 'Teacher')
            getUniqueDatas(); //Children Data on Initialization
    }, [testType])

    useEffect(() => {
        if(selectedData.length!=0)
         {
            createPayloadHandler(1);
            PaginationRef.current.setCurrentPageNumberToDefault();
            dispatch({type:'CLEAR_SEARCH_DATA'})
         }
    }, [selectedData])

    useEffect(() => {
        if(payload.SearchData!=undefined && payload.SearchData.length!=0 && payload.SearchData!=null)
        {
            createPayloadHandler(1);
            PaginationRef.current.setCurrentPageNumberToDefault();
        }
    }, [SearchData])


    const sendCurrentPageData = (pageNumber) => {
        createPayloadHandler(pageNumber)
    }
    
    const returnStateHandler = (clickedyes,clickedclose) => {
        setshowModal(clickedclose);
    }

      let [filters, setFilters] = useState({username:[]});
      const [uniqueDatas, setUniqueDatas] = useState({username:[]})
      const filtersSelected = (selected,type) => {
        if(type=='username')
        {
            filters = {...filters,username:selected}
            var value = [];
            for (var i = 0, l = filters['username'].length; i < l; i++) {
             
                {
                    value.push(filters['username'][i].value);
                }
            }
            setStudentId(value);
            setFilters(filters);  //TO DISPLAY SELECTED(CHECKED) VALUES
        }
    }
    const getUniqueDatas = () => {
        let username = [];
        UserLogin?.childrenData?.map((el,index)=>{
            let object = {label:el.userName,value:el.id}
            // let flagtitle = username.find(o => o.value === el.title);
            // if(!flagtitle)
            {
                username.push(object);
            }
        });
        uniqueDatas.username = username;
        setUniqueDatas({...uniqueDatas});
    }

    return (
        <div className="container-fluid" style={{position:'relative'}}>
            {showModal && <Popup from="AllTests" title="Assigned Test" 
            body={"Successfully Assigned "+"to "+"selected students"} returnStateHandler={returnStateHandler} />}
            {
              (UserLogin.userType=='Parent' || UserLogin.userType=='Teacher') &&
              <Card className="" >
                        <div className="row smalltext ">
                            <h6 className="px-3 smalltext"> Choose Student : <span className="px-1" style={{color:'red'}}>*</span> </h6>
                            <div className="px-3 paddedInput" >

                                <div>{CustomDropDown(uniqueDatas,'username',filtersSelected,filters)}</div>
                                
                            </div>
                        </div>
                </Card>
             }
            <div className="row">
            {
                AllTestsState?.loadingTests?
                <div className='d-flex flex-column justify-content-center align-items-center'> 
                    
                        <div className="d-flex flex-column justify-content-center align-items-center" style={{color:'brown',fontWeight:'bold'}} > Exams </div>
                        <Spinner variant="primary" animation="grow" style={{height:'100px',width:'100px',margin:'20px'}} />
                        
                </div>
                :
                (AllTestsState?.pageResponse?.length==0) ?
                <div className='d-flex flex-column justify-content-center align-items-center'>
                        
                        <div className='d-flex flex-column justify-content-center align-items-center' style={{color:'brown',fontWeight:'bold'}} > Exams </div>
                        {
                             <img src={comingsoon1} height='300px' width='300px' />
                        }

                </div>
                :
                AllTestsState?.pageResponse?.map((el)=>
                    <TestCard key={el.id} SelectedStudentIDs={studentId} subjectColor={subjectListColors[el.subjectName]} gradeColor={gradeListColors[el.gradeName]}  test={el} />
                )
            }
            </div>

            <div>
                <Pagination ref={PaginationRef} sendCurrentPageData={sendCurrentPageData}  PaginationLength={AllTestsState?.paginationLength} />
            </div>
        </div>
    )
}

export default AllTests
