import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router , Switch, Route,Link,useHistory, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {setCookie,getCookie} from '../HelperFunctions/CookieSettings';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import CompanyName from '../Home/CompanyName';


function SearchBar(props) {
  
    const UserLogin = useSelector(state => state.LoginReducer);
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const location = useLocation();
    const history=useHistory();
    let CurrentPath = location.pathname;

    function saveInput(v){
        dispatch({type:'SET_SEARCH_DATA',payload:v});
        
        if(CurrentPath!='/exams' && CurrentPath!='/myTests')
        {
            history.push('/exams');
        }
    }
    
    const processChange = useCallback(
        debounce(saveInput, 300)
      , []);
    

    return (
            <div style={{position:'fixed',background:'black',height:'8vh',textAlign:'center',top:props.view=='mobile'?'7vh':'0',left:0,right:0,
        width:'100%',alignItems:'center',display:'flex',fontWeight:'bolder',color:'white',zIndex:10}}>
               
                {
                    props.view=='desktop' &&
                    <>
                        <div className="header_left_bar" >
                            <div  id="bar" style={{}} className="cursor-pointer" onClick={()=>props.openSideNav()}>
                                <i className="fa fa-bars customLink" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="header_left" >
                            <Link to="/" className="noLink" > <CompanyName/> </Link>
                        </div>
                    </>
                }
                
                    <form autoComplete="off" style={{width:props.view=='mobile'?'90%':'60%',height:'5vh',border:'2px solid #ff8000'}}>
                        <input autoComplete="off" name="SearchByKeyword" placeholder={"Search by Title / Subject / Grade "} type="text" value={searchText} onChange={(e)=>{setSearchText(e.target.value);processChange(e.target.value)}} 
                         style={{width:'100%',height:'100%'}} />
                    </form>
                        <button style={{width:props.view=='mobile'?'15%':'5%',background:'#ff9933',height:'5vh',border:'2px solid #ff8000'}}>
                      
                        <i className="fa fa-search" ></i></button>

                        {
                            window.screen.width>770&&
                            <div className='header_right' style={{color:'white',marginLeft:'5px'}}>Welcome &nbsp;
                    
                                {(UserLogin.username=='undefined' || UserLogin.username=='')?' User':UserLogin.username}
                            </div>  
                        }
                    
                       
            </div>
        
    )
}

export default SearchBar
