export function getFormQuestions(){
let Questions = [
    {
      section: 1,
      items: [
       
        {
          label: 'Test Mode',
          type: 'select',
          value: 'testTypeId',
          disabled:false,
          options: [ {'name':'Online','id':1},{'name':'Offline','id':2}]
        },
        {
          label: 'Number of Questions',
          type: 'select',
          value: 'randomNumber',
          disabled:false,
          options: [{'name':10,'id':10},{'name':20,'id':20},{'name':30,'id':30}]
        }
      ]
    },
    {
        section: 2,
        items: [
          {
            label: 'Language',
            type: 'select',
            value: 'language',
            disabled:false,
            options: [ {id:1,name:'Tamil'}, {id:2,name:'English'}, {id:3,name:'Sinhala'}]
          },
          {
            label: 'Grade',
            type: 'select',
            value: 'gradeID',
            disabled:true,
            options: []
          },
          {
            label: 'Subject',
            type: 'select',
            value: 'subjectID',
            disabled:true,
            options: []
          },
          {
            label: 'Topic',
            type: 'select',
            value: 'topicID',
            disabled:true,
            options: [],
            required: false
          },
          {
            label: 'Sub Topic',
            type: 'select',
            value: 'subTopicId',
            disabled:true,
            options: [],
            required: false
          }
        ]
    },
    {
        section: 3,
        items: [
          {
            label: 'Title',
            type: 'text',
            value: 'title'
          },
          {
            label: 'Description',
            type: 'text',
            value: 'description'
          }
        ]
    },
    {
      section: 4,
      items: [
        {
          label: 'Expiration Date',
          type: 'date',
          value: 'endDate'
        } ,
        {
          label: 'Exam Duration (In Minutes)',
          type: 'range',
          value: 'duration',
          min: "10",
          max : "45",
          default : "30"
        }
        //,
        // {
        //   label: 'Passing Mark',
        //   type: 'number',
        //   value: 'passingMark'
        // }
      ]
    },
    {
      section: 5,
      items: [
        {
          label: 'If you are ready to submit please press `Submit`',
          type: 'information'
        }
      ]
    }
  ]
    return Questions;
}