let initialState = []

const GetGradewiseSubjectsReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_SUBJECTS':
            {
                state=action.payload;
                return [...state];
            }
        
        case 'SET_FORMATTED_SUBJECTS':
            {
                state=action.payload;
                return [...state];
            }

        case 'GET_FORMATTED_SUBJECTS':
            {
                return [...state];
            }
       
        case 'CLEAR_GET_SUBJECTS':
            {
                state = {}
                return [...state];
            }
        default:
             return state;
    }
}

export default GetGradewiseSubjectsReducer;