import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings';
import ApiData from './ApiData.json';

function saveRandomExamAPI(action)
{
    let requestBody = action.payload;
    let http = `${ApiData.BaseApiLink}/Teacher/SaveRandomTest`;

    return axios.post(http,requestBody,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        console.log('Saved Random Test - Success');
        console.log( res.data);
        if(res.data.description == -1 || res.data.description == "-1")
        {
            
            return res.data;
        }
        //////// Generate Random Exam ///////
        let testId = res.data.description;
        let http2 = `${ApiData.BaseApiLink}/Teacher/GenerateRandomQuestions?testid=${res.data.description}&randomNumber=${requestBody.randomNumber}`;
        return axios.get(http2,
        { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
        .then((res)=>{
            console.log('Get Random Test - Success');
            res.data.testId = testId;
            return res.data;
        })
        .catch((err)=>{
            console.log('err-GetRandomTest:',err);
        })
        ////////
    })
    .catch((err)=>{
        console.log('err-saveRandomExamAPI:',err);
        return err;
    })
}

function* saveRandomExamAPIWorkerSaga(action)
{
    try{
            const Result = yield call(saveRandomExamAPI,action);
            yield put({type:'GET_RANDOM_EXAM',payload:Result}) //After calling 2 apis
    }
    catch(e)
    {

    }
}

export function* saveRandomExamAPISaga()
{
    yield takeEvery('SAVE_RANDOM_EXAM_REQUESTED',saveRandomExamAPIWorkerSaga)
}