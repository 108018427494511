import React, { useEffect, useState } from 'react'
import { useSelector , useDispatch, connect } from 'react-redux';
import {  useLocation } from 'react-router';
import { BrowserRouter as Router , Switch, Route,Link,useHistory} from 'react-router-dom';
import ReportsByTopic from './ReportsByTopic'
import './ReportsByTopic.css'

import MCQ from '../QuestionPaper/MCQ';
import TrueOrFalse from '../QuestionPaper/TrueOrFalse';
import Match from '../QuestionPaper/Match';
import Fillin from '../QuestionPaper/Fillin';
import Rearrange from '../QuestionPaper/Rearrange';
import OneWordAnswer from '../QuestionPaper/OneWordAnswer';
import DropDown from '../QuestionPaper/DropDown';

import { Card } from 'react-bootstrap';

function DetailedReport(props) {

  const location= useLocation();  
  const dispatch = useDispatch();
  const [testid, setTestId] = useState(new URLSearchParams(location.search).get('testid'));
  const [studentId, setStudentId] = useState(new URLSearchParams(location.search).get('studentId'));
  const Reports = useSelector(state => state.ReportsReducer); // By ID working
  const QuestionsByTest = useSelector(state => state.GetQuestionsByTestReducer.QuestionsByTest)  // LIST OF SECTIONS // By ID working
  const TestDetails = useSelector(state => state.GetQuestionsByTestReducer.testdetails);
  const CalculatedResults = useSelector(state => state.GetCalculatedResultsReducer);
  const [Results, setResults] = useState({});
  const [DisplayReports, setDisplayReports] = useState({AnswersReport:false})
  const UserLogin = useSelector(state => state.LoginReducer);
  const history = useHistory();
  const [loaded, setLoaded] = useState({Reports:false})

  useEffect(() => {
     if(((UserLogin.username=='undefined' && UserLogin.value?.token=='undefined')))  
    {
       history.push('/login');
    }
  
    dispatch({type:'GET_STUDENT_TEST_REPORT_REQUESTED',payload:{filterValue:testid,filterId:1,filter:true}}); //Student Selected ans
    dispatch({type:'GET_QUESTIONS_BY_TEST_REQUESTED',payload:{testid:testid}}); //Questions of the Test
    dispatch({type:'GET_CALCULATED_RESULT_REQUESTED',payload:{studentId:studentId}}); //Pre Calculated Results
  }, []);

useEffect(() => {
    console.log('CalculatedResults',CalculatedResults);
    for(let i=0;i<CalculatedResults.length;i++)
    {
       if(CalculatedResults[i].testId==testid)
       {
          setResults({...JSON.parse(CalculatedResults[i].calculatedResults)});
       }
    }

}, [CalculatedResults])

useEffect(() => {
  console.log('Reports',Reports);
   if(Reports.length>=1 && !loaded.Reports)
   {
       let len = Reports.length;
       let SelectedAnswers = Reports[len-1]?.studentTestHistories[0]?.correctAnswers; //latest of multiple attempts of same test
       try{
        props.saveAnswersToStore(JSON.parse(SelectedAnswers));
       }
       catch(e){}
       setLoaded({...loaded,Reports:true})
   }
     
}, [Reports])



useEffect(() => { 
  if(DisplayReports.AnswersReport)
  {
    document.getElementById('AnswersReport').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}, [DisplayReports])





    const renderReport = () => {
    return (
    <div  className='AnswersReport'>
        {
            QuestionsByTest?.map((sec,k)=>
                sec.questions.map((el,index) => 
                <div>
                    <h6 style={{color:'dodgerblue'}}>Marks Obtained : {Results[el.qusID].marks} / {Results[el.qusID].total}</h6>
                    {el.qusType=='MCQ' && <MCQ el={el} isResult={true} Results={Results[el.qusID]} qusID={el.qusID} index={el.qusID} key={el.qusID} /> }
                    {el.qusType=='True or False' && <TrueOrFalse el={el} isResult={true} Results={Results[el.qusID]}  qusID={el.qusID} index={el.qusID} key={el.qusID} /> }
                    {el.qusType=='Gap Filling' && <Fillin isResult={true} Results={Results[el.qusID]} el={el} qusID={el.qusID} index={el.qusID} key={el.qusID} /> }
                    {el.qusType=='One word answer' && <OneWordAnswer el={el} isResult={true} Results={Results[el.qusID]} qusID={el.qusID} index={el.qusID} key={el.qusID} /> }
                    {el.qusType=='Match the following' && <Match isResult={true} Results={Results[el.qusID]} el={el} qusID={el.qusID} index={el.qusID} key={el.qusID} /> }
                    {el.qusType=='Re-Ararnge' && <Rearrange isResult={true} Results={Results[el.qusID]} el={el} qusID={el.qusID} index={el.qusID} key={el.qusID} /> }
                    {el.qusType=='Dropdown' && el.qusID!=211 &&  <DropDown isResult={true} Results={Results[el.qusID]} el={el} qusID={el.qusID} index={el.qusID} key={el.qusID} /> }
                    
                    <hr></hr>            
                </div>
                )
            )
        }
    </div>
    )
   }

  return (
    <div className='container-fluid p-2' >
        <div>
            <div className="reportHeading"> Exam Details </div>
                     <div style={{marginLeft:'25px'}}>
                         <div className="row">
                            <div className="col-3">Name</div>  
                            <div className="col-8">{Reports[Reports.length-1]?.title}</div>
                         </div>
                         <div className="row">
                            <div className="col-3">Subject</div>  
                            <div className="col-8">{Reports[Reports.length-1]?.subjectName}</div>
                         </div>
                         <div className="row">
                            <div className="col-3">GradeName</div>  
                            <div className="col-8">{Reports[Reports.length-1]?.gradeName}</div>
                         </div>
            </div>
        </div>
        <div className="reportHeading"> Categorized Report </div>
        
        <ReportsByTopic QuestionsByTest={QuestionsByTest} Results={Results} CalculatedResults={CalculatedResults} />

        <hr></hr>

        <div>
          <div className="reportHeading"> Other Reports </div>
          <Card style={{}} className='detailedReportButton' 
                onClick={()=>{
                  setDisplayReports({...DisplayReports,AnswersReport:!DisplayReports.AnswersReport});
                }} >
                  <Card.Body>
                        Answers Report
                  </Card.Body>
          </Card>

          <div id='AnswersReport'>
              { DisplayReports.AnswersReport && renderReport() }
          </div>
        </div>

    </div>
  )
}

//export default DetailedReport

const mapStateToProps = state => {
  return {
     answersFromStore:state.AnswersReducer,
  }
}
const mapDispatchToProps = dispatch => {
  return {
      saveAnswersToStore:(json) => dispatch({type:'SET_ALL_ANSWERS',payload:json})
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(DetailedReport);