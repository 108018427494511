import React, { useCallback, useRef } from 'react'
import { Form } from "react-bootstrap";
import { useState , useEffect , useMemo } from "react";
import RecaptchaComp from '../User/RecaptchaComp';

export const FormItem =  React.memo( ({ item, onChange, answer ,currentItemIndex})  => {

  const handleChange = (value) => {
    onChange(value, item.value , currentItemIndex);
  }
  const [today,setToday] = useState(new Date());
  const rangeRef= useRef();

  const getMinDate = useCallback(() => {
    const today = new Date();
      let day = today.getDate();
      let month = today.getMonth()+1;
      let year = today.getFullYear();
      //YYYY-MM-DD
      if(day<10) day = "0"+day;
      if(month<10) month = "0"+month;
      const formattedDate = year+"-"+month+"-"+day;
      return formattedDate;
  },[today])

  const getMaxDate = useCallback(() => {
      let ms = new Date().getTime() + 3*(86400000);
      let daysLater = new Date(ms);
      let day = daysLater.getDate();
      let month = daysLater.getMonth()+1;
      let year = daysLater.getFullYear();
      //YYYY-MM-DD
      if(day<10) day = "0"+day;
      if(month<10) month = "0"+month;
      const formattedDate = year+"-"+month+"-"+day;
      return formattedDate;
  },[today])
 

   switch (item.type) {
      case 'text':
        return (
          <>
            <Form.Label>{item.label}</Form.Label>
            <Form.Control
              type="text"
              id={item.label}
              onChange={(e) => handleChange(e.target.value, item.value)}
              value={answer}
            />
          </>
        )
        break;
      case 'number':
          return (
            <>
              <Form.Label>{item.label}</Form.Label>
              <Form.Control
                type="number"
                id={item.label}
                onChange={(e) => handleChange(e.target.value, Number(item.value))}
                value={answer}
                min={item.min}
                max={item.max}
              />
            </>
          )
          break;
      case 'range':
            return (
              <>
                <Form.Label>{item.label}</Form.Label>
                <Form.Label>{rangeRef?.current?.value || item.default}</Form.Label>
                <Form.Control
                  ref = {rangeRef}
                  type="range"
                  id={item.label}
                  onChange={(e) => handleChange(e.target.value, Number(item.value))}
                  value={answer || item.default}
                  min={item.min}
                  max={item.max}
                  className="form-range"
                />
              </>
            )
            break;
      case 'password':
        return (
          <>
            <Form.Label htmlFor="inputPassword5">{item.label}</Form.Label>
            <Form.Control
              type="password"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              onChange={(e) => handleChange(e.target.value, item.value)}
            />
          </>
        )
        break;
     case 'date':
          return (
            <>
              <Form.Label >{item.label}</Form.Label>
              <Form.Control
                type="date"
                id="date"
                min={getMinDate()}
                max={getMaxDate()}
                value={answer}
                onChange={(e) => handleChange(e.target.value, item.value)}
              />
            </>
          )
          break;
      case 'information':
        return (
          <p>
            {item.label}
          </p>
        )
      case 'select':
        return (
          <div className="mt-2">
            <Form.Select disabled={item.disabled} key={item.label} aria-label={item.label} value={answer} 
            onChange={(e) => handleChange(e.target.value, item.value)}> 
              {
                 <option key={item.label+'Default'} value={'undefined'} style={{backgroundColor:'grey'}}>Please Select {item.label}</option>
              }
              
              {
                item.options?.map((opt, index) => {
                  return (
                    item.value=='gradeID'?
                    <option key={opt.id}  value={opt.id}>{opt.grade}</option>
                    : item.value=='subjectID'?
                    <option key={opt.id}  value={opt.id}>{opt.subject}</option>
                    : item.value=='topicID' ?
                    <option key={opt.id}  value={opt.id}>{opt.topic}</option>
                    : item.value=='subTopicId' ?
                    <option key={opt.id}  value={opt.id}>{opt.subTopic}</option>
                    :
                    <option key={opt.id}  value={opt.id}>{opt.name}</option>
                  )
                })
              }
            </Form.Select>
            {/* {!item.disabled && item.options.length==0 && <span>Oops! No data available!</span>} */}
          </div>
        )
      
      return (
        <></>
      )
    }
  })
