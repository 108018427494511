
import React, { useEffect,useState} from 'react'
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { BrowserRouter as Router , Switch, Route,Link,useHistory} from 'react-router-dom';
import {setCookie,getCookie} from '../HelperFunctions/CookieSettings'
import './Exams.css'
import Popup from '../HelperComps/Popup';

function TestCard(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { title , subjectName , gradeName,language, duration ,id} = props.test;
    const {subjectColor,gradeColor} = props
    const SearchData = useSelector(state => state.SearchReducer);
    const UserLogin = useSelector(state => state.LoginReducer);
    const [showModal, setshowModal] = useState(false);


    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
      
    
    const getTest = () =>{
        //Commenting next 2 lines as id has been added in Test.js
        // dispatch({type:'GET_QUESTIONS_BY_TEST_REQUESTED',payload:{testid:id}});
        // dispatch({type:'GET_TEST_DETAILS_BY_TEST',payload:props.test});

        //if(QuestionsByTest!=undefined)
        {
            // const win = window.open("/", "_blank");
            // win.focus();
            history.push({pathname:`/test/${id}`,state:{previousPath:'/exams',test:props.test}});
        }
    }

    const assignTest = () =>{
       //Number(getCookie('domockexamUserID')) OR 2=parent
       if(props.SelectedStudentIDs.length==0) 
       {
        setshowModal(true);
        return;
       }
       console.log('ASSIGN TESTS ',props.SelectedStudentIDs);
        let reqBody = []
        for(let i=0;i<Number(props.SelectedStudentIDs.length);i++)
        {
            let obj = {
                "StudentId":Number(props.SelectedStudentIDs[i]),
                "TestId":id,
                "Assigner":2,
                "AssignedOn":new Date().toISOString(),
                "StatusId":1,
                "Active":true
            }
            reqBody.push(obj);
        }
       dispatch({type:'SAVE_STUDENT_TEST_REQUESTED',payload:reqBody});
    }

    const getHighlightedText = (text) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text?.toString().split(new RegExp(`(${SearchData})`, 'gi'));
        return <span> { parts?.map((part, i) => 
            <span key={i} style={part.toLowerCase() === SearchData.toLowerCase() ? { fontWeight: 'bold',background:'yellow' } : {} }>
                { part }
            </span>)
        } </span>;
    }

    const returnStateHandler = (clickedyes,clickedclose) => {
        setshowModal(clickedclose);
    }


    return (
        <div  className="col-12 col-md-6 col-lg-4">
            {showModal && <Popup from="TestCard" title="No Students Selected" returnStateHandler={returnStateHandler}
            body={"Cannot assign tests. Please select students from your student list"} />}
            {
                
                <Card style={{margin:'5px'}} >
                    {/* <> Prioritize exams</> (subjectName=='Scholarship')?'#ff8000': */}
                
                    <Card.Header style={{background:gradeColor }} >
                        <Card.Title>Title  : {getHighlightedText(title)}</Card.Title>
                    </Card.Header>
                
                <Card.Body style={{margin:'0px',paddingBottom:'0px'}}>
                    <div className="row flex-nowrap">
                        <div className="col-11">
                            <Card.Text>
                                    <div>Subject : {getHighlightedText(subjectName)} </div>
                                    <div>Grade : {getHighlightedText(gradeName)} </div>
                                    <div>Duration : {duration} minutes </div>
                                    <div style={{fontSize:'11px'}}>Test Id : {id}  </div>
                            </Card.Text>
                            {
                                UserLogin.userType=='Parent' ?
                                <Button variant="primary" onClick={()=>assignTest()}>Assign Test</Button>
                                :
                                UserLogin.userType=='Teacher' ?
                                <>
                                <Button variant="primary" onClick={()=>getTest()}>Review Test</Button> &nbsp;
                                <Button variant="primary" onClick={()=>assignTest()}>Assign Test</Button>
                                </>
                                :
                                <Button variant="primary" onClick={()=>getTest()}>Take Test</Button>
                            }
                        </div>
                        <div className="col-1" style={{background:'repeating-linear-gradient(45deg,'+subjectColor+' 0px,'+subjectColor+' 20px,'+gradeColor+' 20px,'+gradeColor+' 40px)'}}>
                            
                        </div>
                    </div>
                    <div className="row flex-nowrap" >
                            <div className="col-11"></div>
                            <div className="col-1">
                                <div className="d-flex justify-content-center"> 
                                    {language=='1' && <span  style={{fontWeight:'bold',fontSize:'15px'}}>த</span>}
                                    {language=='2' && <span  style={{fontWeight:'bold',fontSize:'15px'}}>E</span>}
                                    {language=='3' && <span  style={{fontWeight:'bold',fontSize:'15px'}}>සි</span>}
                                </div>
                            </div>
                    </div>

                </Card.Body>
                
                </Card>
            }
           
        </div>
    )
}

export default TestCard
