import React, { useEffect } from 'react'
import RecaptchaComp from './RecaptchaComp';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

function UserRegistrationFormSubmit(props) {

  const {role,roleName,data,roleRequested,StudentData,validate,AddStudent,validate2} = props;
  const [Human, setHuman] = useState(false);
  const [TcChecked, setTcChecked] = useState(false);
  const [registerButton, setRegisterButton] = useState({disabled:false,loading:false})
  const dispatch = useDispatch();
  const RegistrationResult = useSelector(state => state.RegisterReducer)


  useEffect(()=>{
        if(RegistrationResult?.result!=undefined)
        {
            setRegisterButton({...registerButton,disabled:false})
        }
  },[RegistrationResult])
  const register = (e) => {
    
        e.preventDefault();
        let validated = validate(e,roleName);
        let validated2 = true;
        if(roleName=='Minor')
        {
            validated2 = validate2(e,'Minor')
        }
        console.log("Registration Form Validation ",validated)
        if(validated && validated2)
        {
            setRegisterButton({...registerButton,disabled:true})
            data.genderId = data.gender=="Male"?1:2;
            let roleId = role;
            let roleRequestedID = roleRequested;
            let reqBody = {
                "FirstName":data.firstname.result,
                "LastName":data.surname.result,
                "UserName":data.email.result.trim(),
                "PhoneNumber":data.phone.result,
                "Gender":data.gender.result,
                "genderId":data.genderId,
                "Role":roleId,
                "Email":data.email.result.trim(),
                "Password":data.password.result,
                "ConfirmPassword":data.password.result
            };
           
            if(role == 2 || role==7)
            {
                let studData = StudentData!=undefined?StudentData:data;
                
                let studentModel = {
                    "StudentFirstName":studData.firstname.result,
                    "StudentLastName":studData.surname.result,
                    "StudentGender":studData.genderId!=undefined?studData.genderId:StudentData.gender.result=="Male"?1:2,
                    "StudentPassword":studData.password.result,
                    "StudentConfirmPassword":studData.password.result,
                    "StudentUserName":studData.username.result!=""?studData.username.result:studData.email.result.trim(),
                    "RoleRequested":roleRequestedID,
                    "GradeLevels":Number(studData.grade.result),
                    "StudentDistrict":data.district.result,
                    "LanguageKnown":"Tamil,English,Sinhala",
                    "Institution":studData.school.result,
                    "StudentAccountRecoveryAnswers":[{
                        "QuestionId":Number(studData.secretquestion.result),
                        "Answer":studData.secretanswer.result
                        }]
                }
                reqBody.StudentModel = studentModel;
            }
            console.log("REGISTRATION REQUEST BODY ",reqBody)
            dispatch({type:'CLEAR_REGISTER_USER'})
            dispatch({type:'REGISTER_USER_REQUESTED',payload:reqBody});
        }
        else
        {
            toast.error('Please check for the validation errors in the form')
        }
  }
    
  return (
    <div>
    <div data-testid={'testid-UserRegistrationFormSubmit'} className="d-flex flex-column align-items-center">
        <div className="form-check cursor-pointer">
            <input data-testid='testid-tcs' className="form-check-input cursor-pointer" type="checkbox" onClick={()=>setTcChecked(!TcChecked)} value={TcChecked}  id="tcs" />
            <label className="form-check-label cursor-pointer" htmlFor="tcs">
                <span className="cursor-pointer" style={{color:'red'}}>*</span>  
                Accept terms and conditions
            </label>
        </div>
        <RecaptchaComp verifyHumanCallback={()=>setHuman(true)} />
        
        <div  className='d-flex'> 
        <button data-testid='testid-registerbutton' className="btn btn-primary register lrbutton" 
        disabled={!(Human && TcChecked) || registerButton.disabled} 
        onClick={(e)=>register(e)} type="button">Register</button>
        {
            registerButton.disabled &&
            <div className="d-flex justify-content-center align-items-center" style={{ overflow: 'hidden' }}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }
        
        </div>
    </div>  
    </div>
  )
}

export default UserRegistrationFormSubmit
