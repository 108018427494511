import React, { useEffect, useState } from 'react'
import HtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';

function TrueOrFalse(props) {
    const {el,index,qusID,isResult,Results,sectionID,isCorrectAnswers} = props;
    const [answers, setanswers] = useState([]);
    let deSelect = (e,id,index,ind) => {
        if(document.getElementById(id).classList.contains('imChecked'+index+ind))
        {
            document.getElementById(id).checked=false;
            document.getElementById(id).style.background='darkgrey';
            document.getElementById(id).classList.remove("imChecked"+index+ind);
        }
        else
        {
            var elems = document.querySelectorAll(".imChecked"+index+ind);
            [].forEach.call(elems, function(el) {
                el.classList.remove("imChecked"+index+ind);
            });
            document.getElementById(id).classList.add('imChecked'+index+ind);
           // console.log(document.getElementById(id).classList)
            
        }
        var els = document.querySelectorAll(".imChecked"+index+ind);
        if(els.length==0)
        {
            answers[ind]='';
            let ans = [...answers]
            setanswers([...answers])
            let answer = {index:index,qusId:qusID,selectedAnswer:ans,qusType:'True or False',lastUpdatedSectionIndex:sectionID}  
            props.saveAnswersToStore(answer);
        }
    }
    useEffect(() => {
        if(props.answersFromStore[index])
        {
            setanswers(props.answersFromStore[index].selectedAnswer)
        }
        else
        {
            for(let i=0;i<JSON.parse(el.correctOption).length;i++)
            {
                answers[i]='';
            }
            setanswers([...answers])
        }
      
    }, [])
    const saveAnswers = (ind,ans,opid) => {
        var els = document.querySelectorAll(".imChecked"+index+ind);
        if(els.length==0)
        return;
        answers[ind]={id:opid,option:ans};
        setanswers([...answers])
        let answer = {index:index,qusId:qusID,selectedAnswer:answers,qusType:'True or False',lastUpdatedSectionIndex:sectionID}  
        props.saveAnswersToStore(answer);
    }

    const [Question, setQuestion] = useState(el);
   


    return (
        <div>
            <div>
                    <div style={{marginLeft:'20px',marginRight:'20px'}}>
                    <h5>{Parser(Question.instruction)}</h5>
                        <div className="container-fluid">
                        {/* <div style={{width:'100%',overflow:'auto',lineHeight:'2.5'}}>{HtmlParser(el.questionName)}</div> */}
                        {el.qusType=="True or False" &&      
                        <div className="row">
                             <div className="col-6 col-md-9" style={{borderWidth:'2px 0px 2px 2px',borderStyle:'solid',borderColor:'black',fontWeight:'bold',background:'darkgrey',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                           
                             </div>
                            {
                                el.customOptions.map((option,index)=>
                                <div className="col-2 col-md-1" style={{borderWidth:'2px 2px 2px 2px',borderStyle:'solid',borderColor:'black',fontWeight:'bold',background:'darkgrey',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            {option.option}
                                </div>
                                )
                            }
                  
                        </div>}
                        <div style={{borderCollapse:'collapse'}}>
                              {     el.qusType=="True or False" ?
                                JSON.parse(el.correctOption).map((q,ind)=>
                                  <div className="row">
                                     
                                      <div className="col-6 col-md-9" style={{borderWidth:'0px 0px 2px 2px',borderStyle:'solid',borderColor:'black'}}>
                                            {Parser(el.questionName)}
                                      </div>
                                      {
                                el.customOptions.map((option,optind)=>
                                      <div className="col-2 col-md-1" style={{borderWidth:'0px 2px 2px 2px',borderStyle:'solid',borderColor:'black', textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center',
                                      background:isResult && (isCorrectAnswers || Results[ind].correct==Results[ind].choosed ) && Results[ind].correct==option.option ? '#66ff66' : isResult && Results[ind].choosed!=Results[ind].correct && Results[ind].choosed==option.option ?'#ff704d' : 'none'}}
                                     >
                                            <label className={"customcheck"}>
                                                <input disabled={isResult} checked={!isResult && props.answersFromStore[index]?.selectedAnswer[ind]?.option==option.option } onClick={(e)=>{deSelect(e,option.option+index+ind,index,ind);saveAnswers(ind,option.option ,option.option )}} type="radio" name={''+index+ind} id={option.option+index+ind} />
                                                <span style={{marginTop:'-14px'}} className="checkmark"></span>
                                            </label>
                                      </div>
                                )
                                        }
                                     
                                  </div>
                                  ) :
                                  <div>
                                 <div className="row">
                                    <div className="col-4 col-md-9" style={{borderWidth:'2px 0px 2px 2px',borderStyle:'solid',borderColor:'black',fontWeight:'bold',background:'darkgrey',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                
                                    </div>
                                    <div className="col-8 col-md-3" style={{borderWidth:'2px 2px 2px 2px',borderStyle:'solid',borderColor:'black',fontWeight:'bold',background:'darkgrey',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                    Select True or False
                                    </div>
                                    
                                </div>
                                  {JSON.parse(el.correctOption).map((q,ind)=>
                                  <div>
                                      
                                      <div className="row">
                                            <div style={{borderWidth:'0px 0px 2px 2px',borderStyle:'solid',borderColor:'black',display:'flex',alignItems:'center'}} className="col-4 col-md-9">{q}</div>
                                            <div className="col-8 col-md-3" style={{borderWidth:'0px 2px 2px 2px',borderStyle:'solid',borderColor:'black',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px'}}>
                                            <select style={{fontSize:'15px'}} className="mydropdown dropdown-toggle" data-flip="false" 
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-dropup-auto="false" >  
                                                <option  value="selans"  className="selans" style={{fontStyle:'normal'}} > Select answer </option>
                                                <option className="myoption" value={true}>True</option>
                                                <option className="myoption" value={false}>False</option>
                                            </select>
                                            </div>
                                      </div>
                                  </div>
                                  )}
                                  </div>
                              }  
                        </div>
                    </div>
                    </div>
                    
                </div>
        </div>
    )
}

//export default TrueOrFalse


const mapStateToProps = state => {
    return {
       answersFromStore:state.AnswersReducer,
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
        saveAnswersToStore:(json) => dispatch({type:'SET_ANSWERS',payload:json})
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(TrueOrFalse);