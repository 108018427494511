import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function LoginAPI(action)
{
    console.log(action.payload);
    let requestBody = action.payload;

    let http = `${ApiData.BaseApiLink}/account/login`;

    return axios.post(http,requestBody,
        { headers:{"Content-Type":"application/json"}})
            .then((res)=>{
                console.log('LoginSAGA Success');
                return res.data;
            })
            .catch((err)=>{
                console.log('err',err.response);
                return err;           
            })
}

function* LoginWorkerSaga(action)
{
    try{
            const User = yield call(LoginAPI,action);
            console.log('LoginWorkerSaga',User);
            yield put({type:'LOGIN_USER',payload:User})
    }
    catch(e)
    {

    }
}

export function* LoginSaga()
{
    yield takeEvery('LOGIN_USER_REQUESTED',LoginWorkerSaga)
}