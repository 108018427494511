let initialState = [];

const SendStudentTeacherInvitationReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'SEND_STUDENT_TEACHER_INVITATION':
            {
                state = action.payload;
                return {...state};
            }
        case 'RESET_SEND_STUDENT_TEACHER_INVITATION':
                {
                    state = [];
                    return {...state};
                }
        default:
             return {...state};
    }
}

export default SendStudentTeacherInvitationReducer;