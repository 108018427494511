import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function getTestByIdFromAPI(action)
{
   let payload = action.payload;
   let http = `${ApiData.BaseApiLink}/StudentTest/GetTest`;
   http += '?isEncrypted='+false;
   

   return axios.post(http,payload,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
        .then((res)=>{
            return res.data;
        })
        .catch((err)=>{
            console.log('err',err.response);
            return err;           
        })

}

function* getTestByIdWorkerSaga(action)
{
    try{
            const Test = yield call(getTestByIdFromAPI,action);
            console.log('getTestByIdWorkerSaga',Test);
            yield put({type:'GET_TEST_BY_ID',payload:Test.response})
    }
    catch(e)
    {

    }
}

export function* getTestByIdSaga()
{
    yield takeEvery('GET_TEST_BY_ID_REQUESTED',getTestByIdWorkerSaga)
}