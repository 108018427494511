let initialState = {
    RandomExam:[] , loading:true
}

const RandomExamReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_RANDOM_EXAM':
            {
                state.RandomExam=action.payload;
                state.loading=false;
                return {...state};
            }
        case 'RESET_RANDOM_EXAM':
            {
                state={RandomExam:[],loading:true};
                return {...state};
            }
        default:
             return state;
    }
}

export default RandomExamReducer;