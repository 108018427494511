import logo from './logo.svg';
import './App.css';
import Register from './Components/User/Register';
import Header from './Components/Header/Header';
import { BrowserRouter as Router , Switch, Route,Link,useHistory, BrowserRouter , Redirect, useLocation} from 'react-router-dom';
import Login from './Components/User/Login';
import 'font-awesome/css/font-awesome.min.css';
import Home from './Components/Home/Home';
import Exams from './Components/Exams/Exams';
import { useEffect } from 'react';
import axios from 'axios';
import Test from './Components/Test/Test';
import Report from './Components/Reports/Report';
import { useDispatch, useSelector } from 'react-redux';
import {setCookie,getCookie} from './Components/HelperFunctions/CookieSettings';
import { HashRouter } from 'react-router-dom';
import ForgotPassword from './Components/User/ForgotPassword';
import ResetPassword from './Components/User/ResetPassword';
import ChangePassword from './Components/User/ChangePassword';
import AssignedTests from './Components/AssignedTests/AssignedTests';
import EmailConfirmation from './Components/User/EmailConfirmation';
import AddStudent from './Components/User/AddStudent';
import SearchAndAddStudent from './Components/Teacher/SearchAndAddStudent';
import ContactUs from './Components/Home/ContactUs';
import DetailedReport from './Components/Reports/DetailedReport';
import StudentTeacherInvitationResponse from './Components/Teacher/StudentTeacherInvitationResponse';
import MultiStepForm from './Components/GenerateExam/MultiStepForm';
import { ToastContainer, toast } from 'react-toastify';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );

function App(props) {

      
      const UserLogin = useSelector(state => state.LoginReducer);
  
      //  useEffect(() => {
      //     console.log('LOOGED IN USER DETAILS ',UserLogin)
      //  }, []);
       console.log('Enviroinment: ',process.env.NODE_ENV)
       
  return (
    <div data-testid='testid-app' className="">
      <div className="dim" id="dim"></div>
      <ToastContainer />
      <HashRouter >
            <div >
            <Switch>
            <Route path="/test/:testid">
                  <Test />
            </Route>
            <Route path="/myTests">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><Exams testType={'myTests'} /></div>
            </Route>
            <Route path="/generateExams">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><MultiStepForm /></div>
            </Route>
            <Route path="/home">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><Home /></div>
                  
            </Route>
            <Route path="/contactUs">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><ContactUs /></div>
            </Route>
            <Route path="/detailedReport">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><DetailedReport /></div>
            </Route>
            <Route path="/report">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><Report /></div>
            </Route>
            <Route path="/assignedtests">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><AssignedTests /></div>
            </Route>
            <Route path="/exams">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><Exams /></div>
            </Route>
            <Route path="/Teacher/addStudent">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><SearchAndAddStudent /></div>
            </Route>

            <Route path="/addStudent">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><AddStudent /></div>
            </Route>

            
            <Route path="/studentinvitationresponse">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><StudentTeacherInvitationResponse /></div>
            </Route>
            
            <Route path="/ConfirmEmail">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><EmailConfirmation /></div>
            </Route>
            <Route path="/changePassword">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><ChangePassword /></div>
            </Route>
            <Route path="/ResetPassword">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><ResetPassword /></div>
            </Route>
            <Route path="/forgotpassword">

                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><ForgotPassword /></div>
            </Route>
            <Route path="/login">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><Login /></div>
                  {/* <Redirect to='/login'  /> */}
            </Route>
            <Route path="/">
                  <Header ></Header>
                  <div style={{marginTop:'15vh'}}><Register/></div>
            </Route>
            </Switch>
            </div>
      </HashRouter>
      
    </div>
  );
}

export default App;
