import React from 'react'
import CompanyName from '../Home/CompanyName'
import './Home.css';

function Home() {
    return (
            <div data-testid='testid-home' className="col-lg-3 text-center d-flex justify-content-center mb-3 mb-sm-0"
                  style={{minHeight:window.screen.width<=770?'50vh':'85vh',backgroundColor:'#0D6EFD'}}>
                    <div className="" >
                        <div className="card-body d-flex flex-column justify-content-center align-items-center" >
                            <h3><CompanyName/></h3>
                            
                            <div className="d-flex justify-content-center align-items-center " 
                                 style={{height:'20vh',width:'100%',backgroundColor:'green'}}>
                                    <span className='seesaw'><CompanyName/> </span> 
                            </div>
                        </div>
                    </div>
            </div>
    )
}

export default Home
