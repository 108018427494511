import React, { useState,useEffect } from 'react'
import { components } from "react-select";

import { Button } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default as ReactSelect } from "react-select";
import StudentDetails from '../User/StudentDetails';

function SearchStudents(props) {

    const [options, setOptions] = useState([]);
   const dispatch = useDispatch();
   const defaultOptions = useSelector(state => state.SearchStudentReducer);
   const [searchValue, setSearchValue] = useState("");
   const InvitationSent = useSelector(state => state.SendStudentTeacherInvitationReducer);
   const [ isAlertVisible, setIsAlertVisible ] = useState(true);


   useEffect(() => {
    let reqBody = {
      "FirstName":'',
      "LastName":"",
      "Grades":[],
      "Districts":[],
      "Gender":"",
      "UserName":"",
      "Institution":[]
      }
    dispatch({type:'SEARCH_STUDENT_REQUESTED',payload:reqBody});
   }, [])
   

  const onInputChange = (event) => {
    console.log('oninputchange',event.target.value)
    processChange(event.target.value);
    // setOptions(
    //   defaultOptions.filter((option) => option.includes(event.target.value))
    // );
  };

  useEffect(() => {

    let res = [];
    let studs = [];
    if(defaultOptions?.students)
    {
      res = defaultOptions?.students
      for(let i=0;i<res.length;i++)
      {
        let object = {label:res[i].studentFirstName+' '+res[i].studentLastName+' ('+res[i].studentUserName+')',value:res[i]}
        studs.push(object);
      }
    }

    let SelectedStuds =[];
    for(let i=0;i<filters.length;i++)
    {
        SelectedStuds.push(filters[i].value.userId)
    }

    let UnSelectedStuds = studs.filter(function(obj) {
        return !SelectedStuds.includes(obj.value.userId)  });

    setOptions([...UnSelectedStuds])
    console.log('defaultOptions',res)
    

  }, [defaultOptions])
  
  
  
  function searchInput(fname){
     let reqBody = {
      "FirstName":fname,
      "LastName":"",
      "Grades":[],
      "Districts":[],
      "Gender":"",
      "UserName":"",
      "Institution":[]
      }
    dispatch({type:'SEARCH_STUDENT_REQUESTED',payload:reqBody});
  }

  const processChange = useCallback(
    debounce(searchInput, 500)
  , []);

  const handleInputChange = (inputValue) => {
    
    if(searchValue != inputValue)
    processChange(inputValue);

    setSearchValue(inputValue);
  };


    const [filters, setFilters] = useState([]);

    const filtersSelected = (selected) => {
        console.log(selected);
        setFilters([...selected]); 
    }

    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={()=>null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
      };

      
    const CustomDropDown = () => {
        return <ReactSelect
         options={options}
         isMulti
         closeMenuOnSelect={false}
         hideSelectedOptions={true}
         components={{
             Option
         }}
         onInputChange={handleInputChange}
         onChange={(sel)=>filtersSelected(sel)}
         allowSelectAll={true}
         value={filters}
         />
     } 

    const  sendStudentTeacherInvite = (studentId) => {
        console.log('sendStudentTeacherInvite',studentId)
        dispatch({type:'SEND_STUDENT_TEACHER_INVITATION_REQUESTED',payload:{studentId:studentId}})
    }

    useEffect(() => {
      setIsAlertVisible(true);
      const timer = setTimeout(() => {
        setIsAlertVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }, [InvitationSent]);

  return (
    <div>
        SearchStudents
        <div style={{margin:'10px'}}>
            <div style={{height:'500px',position:'absolute',width:'80%'}} >
                {CustomDropDown()}
            </div>
        </div>

        <div className='' style={{background:'aliceblue',marginTop:'100px',position:'relative'}}>
            
          {
            isAlertVisible && 
            <>
                <div className='row text-danger' >
                    <div > {InvitationSent?.response} </div>
                </div>
            </>
          }  
          
          { filters.length!=0 && 
            <div>
                <h6>Selected Students : </h6>
                <div className='row'>
                    {
                        filters.map((choosenStudent,idx)=>
                        <>
                            <div className='col-12'>{idx+1}</div>
                            <div className='col-3'>Student ID</div>   <div className='col-3'>{choosenStudent.value?.studentId}</div>
                            <div className='col-3'>Student First Name</div>   <div className='col-3'>{choosenStudent.value?.studentFirstName}</div>
                            <div className='col-3'>Student Last Name</div>   <div className='col-3'>{choosenStudent.value?.studentLastName}</div>
                            <div className='col-3'>Student Gender</div>   <div className='col-3'>{choosenStudent.value?.studentGender}</div>
                            <div className='col-3'>Student User Name</div>   <div className='col-3'>{choosenStudent.value?.studentUserName}</div>
                            <div className='col-3'>Student Grade</div>   <div className='col-3'>{choosenStudent.value?.grade}</div>
                            <div> <Button onClick={()=>sendStudentTeacherInvite(choosenStudent.value?.studentId)}>Send Invite</Button>  </div>
                            
                            <div className='col-12'><hr></hr></div>

                        </>)
                    }
                </div>
          </div>
          }
        </div>
        <hr></hr>
        <div>
          <h4>My Students</h4>
          <StudentDetails/>
        </div>
        
    </div>
  )
}

export default SearchStudents