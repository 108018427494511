import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Popup from '../HelperComps/Popup';

function StudentTeacherInvitationResponse() {

    const location= useLocation();

    const [res, setRes] = useState(new URLSearchParams(location.search).get('res'));
    const [Id, setId] = useState(new URLSearchParams(location.search).get('Id'));
    const InvitationResponded = useSelector(state => state.StudentTeacherInvitationResponseReducer);

    const history = useHistory();
    const dispatch = useDispatch();

    const [showModal, setshowModal] = useState(false);
    const [ isAlertVisible, setIsAlertVisible ] = useState(true);

    const AcceptOrReject = (res) => {
        {
            dispatch({type:'STUDENT_TEACHER_INVITATION_RESPONSE_REQUESTED',payload:{res:res,Id:Id}});
            setshowModal(true);
            setIsAlertVisible(false);
        }
    }

    const returnStateHandler = (clickedyes,clickedclose) => {
        setshowModal(clickedclose);
    }

    function sendEmail(message) {
        var email = 'info@domockexam.com';
        var subject = 'Enqueries';
        var emailBody = 'Hi, ';
        document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
    }

  return (
    <div>
        <div className="d-flex justify-content-center" >
                 {
                     showModal && 
                     <Popup from="" title="Student Teacher Invitation Response" body={JSON.stringify(InvitationResponded.message)} returnStateHandler={returnStateHandler} />
             
                 }
        </div>

        <div className="d-flex justify-content-center" style={{marginTop:'50px'}}>
            
            <Card className="col-md-8 col-12" style={{overflow:'auto'}}>
            <Card.Body>
               
                {
                    isAlertVisible &&
                    <>
                        <Card.Title className="text-center"> Accept Teacher Invite  </Card.Title>
                        
                        <Card.Text style={{marginTop:'30px'}} className="">
                            <h5 className="text-center">A Teacher has sent you an invite to add your child as a student to look after his/her progress.</h5>
                            
                            <p className="text-center">Please click ACCEPT/REJECT button to ACCEPT/REJECT the invite.</p>
                            <div className="d-flex flex-column justify-content-center align-items-center"  >
                                <button className="btn btn-primary" style={{width:'50%',height:'100px',fontSize:'48px',marginTop:'10px'}} 
                                onClick={()=>AcceptOrReject(1)} >ACCEPT</button>
                            </div>

                            <div className="d-flex flex-column justify-content-center align-items-center"  >
                                <button className="btn btn-primary" style={{width:'50%',height:'100px',fontSize:'48px',marginTop:'10px'}} 
                                onClick={()=>AcceptOrReject(2)} >REJECT</button>
                            </div>

                        </Card.Text>

                        <hr></hr>
                   </>
                }
                
                   <div className="text-center">
                        <p>Need Help?</p>
                        <p>Mail us at <b style={{color:'royalblue',cursor:'pointer'}} onClick={()=>sendEmail()}>info@domockexam.com</b> </p>
                   </div>

           </Card.Body>
          
           </Card>
            
       </div>
    </div>
  )
}

export default StudentTeacherInvitationResponse