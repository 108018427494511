import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function getGradesByLangFromAPI(action)
{
    let http = `${ApiData.BaseApiLink}/test/GetGradeLevelsByLanguages`;
    http = http+'?Languages='+action?.payload?.language;
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log('err-getGradesByLang:',err);
    })
}

function* getGradesByLangWorkerSaga(action)
{
    try{
            const Grades = yield call(getGradesByLangFromAPI,action);
            console.log('getGradesByLang---SAGA');
            yield put({type:'GET_GRADES_BYLANG',payload:Grades?.response});
    }
    catch(e)
    {

    }
}

export function* getGradesByLangSaga()
{
    yield takeEvery('GET_GRADES_BYLANG_REQUESTED',getGradesByLangWorkerSaga)
}