import React, { useEffect, useState } from 'react'
import './ReportsByTopic.css'

function ReportsByTopic(props) {

  
  let [ReportsByTopicData, setReportsByTopicData] = useState(null);
  const [loading, setLoading] = useState(true)
  const {QuestionsByTest,Results,CalculatedResults} = props;  

  useEffect(() => {

    ReportsByTopicData = {};
    if(Object.keys(Results).length!=0)
    {
      QuestionsByTest?.map((sec,k)=>
        sec.questions.map((el,index) => {
            if(el.topic==null || el.subTopic==null)
            {}
            else if(ReportsByTopicData[el.topic]==undefined)
            {
              ReportsByTopicData[el.topic] = {}
              ReportsByTopicData[el.topic][el.subTopic] = {};
              ReportsByTopicData[el.topic][el.subTopic]['marks'] = 0;
              ReportsByTopicData[el.topic][el.subTopic]['totalmarks'] = 0;
            }
            else if(ReportsByTopicData[el.topic][el.subTopic]==undefined)
            {
              ReportsByTopicData[el.topic][el.subTopic] = {}
              ReportsByTopicData[el.topic][el.subTopic]['marks'] = 0;
              ReportsByTopicData[el.topic][el.subTopic]['totalmarks'] = 0;
            }
            
            if(ReportsByTopicData!=undefined && ReportsByTopicData[el.topic]!=undefined && ReportsByTopicData[el.topic][el.subTopic]!=undefined && ReportsByTopicData[el.topic][el.subTopic]['marks']!=undefined)
            {
               ReportsByTopicData[el.topic][el.subTopic]['marks'] += Results[el.qusID]?.marks; 
               ReportsByTopicData[el.topic][el.subTopic]['totalmarks'] += 1;
            }      
      }))
     
      setReportsByTopicData({...ReportsByTopicData});
      setLoading(false);     
      console.log('ReportsByTopic : ',ReportsByTopicData)
      
    }
  
        return () => {
          setReportsByTopicData(null);
          
        }
  
  }, [QuestionsByTest,Results,CalculatedResults])
    

  return (
    <div>
         
         <div className='container-fluid'>
            <div className='row' style={{marginLeft:'2px'}}>
                      {
                         loading ?
                         <div> Loading... </div>
                         :
                         Object.entries(ReportsByTopicData).length!=0 ?
                         <>
                            {
                                          Object.entries(ReportsByTopicData).length!=0 ?
                                          Object.entries(ReportsByTopicData).map(([key, value]) => (
                                              <div className='col-4 topic p-2' key={key}>
                                                  <span className='headingFont' >Topic : {key}</span>
                                                  <div className='m-2'> 
                                                  {
                                                      Object.entries(ReportsByTopicData[key])?.map(([key, value]) => (
                                                          <div key={key} className='subtopic p-2'>
                                                              <span className='headingFont' >Sub-Topic : {key}</span>
                                                              <div>
                                                                      Marks : {value.marks} / {value.totalmarks}
                                                              </div>
                                                          </div>
                                                      ))
                                                  }
                                                  </div>
                                              </div>
                                          ))
                                          :
                                          <></>
                                   
                            }
                          </>
                         :
                         <div>
                              <h4 style={{color:'gold',fontStyle:'italic'}}>Categories are yet to come</h4>
                         </div>
                         
                      }
                     
                
            </div>
         </div>
    </div>
  )
}

export default ReportsByTopic