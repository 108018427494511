import React from 'react';
import {validateSecretQuestion,validateFirstName,validatePhone,validateSchool,validateGender,validateGrade,validateEmail,validatePassword,validateUsername,validateSecretAnswer} from '../HelperFunctions/Validations'
import { useDispatch } from 'react-redux';

const validations = {
    "validateFirstName" : validateFirstName,
    "validatePhone" : validatePhone,
    "validateSchool" : validateSchool,
    "validateGender" : validateGender,
    "validateEmail" : validateEmail,
    "validatePassword" : validatePassword,
    "validateUsername" : validateUsername,
    "validateSecretAnswer" : validateSecretAnswer,
    "validateSecretQuestion" : validateSecretQuestion,
    "validateGrade" : validateGrade
  }

function useForm(initialState = {}, typing) {
        const [formData, setFormData ] = React.useState(initialState);
        const dispatch = useDispatch();
  
        
        const resetFormDataHandler = ( data ) => {
            dispatch({type:'CLEAR_REGISTER_USER'})
            setFormData(data);
        }

        const handleInputChange = (e,validationFunction,selectOptionKey) => {
            if(e.target.name=="phone") {
                if(!(e.nativeEvent.data>=0 && e.nativeEvent.data<=9)) return;
            }

            let validationCheck = validations[validationFunction]?.(e.target.value);
            setFormData({ ...formData, [e.target.name] : { ...formData[e.target.name],
                result:e.target.value ,
                validationError:validationCheck
            }});
        };

        const handleValidate = (e,roleName) => {
            e.preventDefault();
            let tempFormData = {...formData};
            let flag=true;
            for(let key in tempFormData)
            {
                let item = tempFormData[key];
                if(item.NotAllowedUsers?.includes(roleName))
                {
                    continue;
                }
                let validationCheck = validations[item.validationFunction]?.(item.result)
                tempFormData[key].validationError =  validationCheck;
                flag = flag && (validationCheck=="" || validationCheck==undefined) 
            }
            setFormData(tempFormData)
            return flag;
        }
    
        return [ formData, handleInputChange ,  handleValidate , resetFormDataHandler ];
    
}

export default useForm
