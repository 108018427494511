import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function StudentTeacherInvitationResponseAPI(action)
{
    const requestParams = action.payload;
    let http = `${ApiData.BaseApiLink}/teacher/StudentInvitationResponse?res=${requestParams.res}&&Id=${requestParams.Id}`;
    console.log(' StudentTeacherInvitationResponseAPI : ',http);
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        console.log('StudentTeacherInvitationResponseAPI ',res.data);
        return res.data;
    })
    .catch((err)=>{
        console.log('err-StudentTeacherInvitationResponseAPI:',err);
    })
}

function* StudentTeacherInvitationResponseAPIWorkerSaga(action)
{
    try{
            const Result = yield call(StudentTeacherInvitationResponseAPI,action);
            console.log('STUDENT_TEACHER_INVITATION_RESPONSE',Result);
            yield put({type:'STUDENT_TEACHER_INVITATION_RESPONSE',payload:Result})
    }
    catch(e)
    {

    }
}

export function* StudentTeacherInvitationResponseAPISaga()
{
    yield takeEvery('STUDENT_TEACHER_INVITATION_RESPONSE_REQUESTED',StudentTeacherInvitationResponseAPIWorkerSaga)
}