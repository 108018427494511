import React, { useEffect, useState } from 'react'
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import MuiTreeItem from "@material-ui/lab/TreeItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { BrowserRouter as Router , Switch, Route,Link,useHistory} from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import './Exams.css';


import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';

import AllTests from './AllTests';
import { useDispatch, useSelector } from 'react-redux';
function Exams(props) {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subjectListColors, setSubjectListColors] = useState({});
    const [gradeListColors, setGradeListColors] = useState({});
    const {testType} = props;

    function getRandomColor() {
      let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
      return color;
    }

    const generateColor = () =>{
        let color = '';
        let colorList = [];
        for(let i=0;i<gradeList.length;i++)
        {
          color =  getRandomColor();
          while(colorList.includes(color))
          {
            color =  getRandomColor();
          }
          colorList.push(color);
          gradeListColors[gradeList[i]]=color;
        }
        for(let i=0;i<subjectList.length;i++)
        {
          color =  Math.random().toString(16).substr(-6);
          while(colorList.includes(color))
          {
            color =  Math.random().toString(16).substr(-6);
          }
          colorList.push(color);
          subjectListColors[subjectList[i]]='#'+color;
        }
    };

    const useStyles = makeStyles({
        
        treeview: {
          background:'#0D6EFD',
          minHeight:'85vh',
          zIndex:1,
          color:'white',
          fontWeight:'bold',
          fontFamily: 'Lucida Console, Courier New, monospace',
          paddingRight:'10px'
        },
        treeviewmobile: {
          background:'#0D6EFD',
          minHeight:'100vh',
          zIndex:1,
          fontSize:'11px',
          color:'white',
          fontWeight:'bold',
          fontFamily: 'Lucida Console, Courier New, monospace',
          paddingRight:'10px',
          marginTop:'30px'
        },
        list: {
          //width: 250,
        },
        fullList: {
          width: 'auto',
        }

      });
      
      const TreeItem = withStyles({
        root: {
          "&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
            background: "#89CFF0"
          },
          // "&.MuiTreeItem-root > .MuiTreeItem-content:hover": {
          //   background: "gray",
          // },
          "&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label": {
            background: "#89CFF0",
          },
          // '@media (hover: none)': {
          //   backgroundColor: 'transparent',
          // },
          "&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-iconContainer svg":{
              background:'gray',
              height:'100%'
          },
          "&.MuiTreeItem-root > .MuiTreeItem-content > .MuiTypography-body1":{
            lineHeight:window.screen.width<770?2.5:1.5
          }
        }
    
      })(MuiTreeItem);
      const classes = useStyles();

      
      useEffect(() => {
          {
             dispatch({type:'GET_SUBJECTS_REQUESTED',gradeId:0})
          }
      }, []);
      
 
  var getSubMenuItem = function (subMenuItems, id,arr) {
    if (subMenuItems) {
        for (var i = 0; i < subMenuItems.length; i++) {
            if(subMenuItems[i].children)
            arr.push(subMenuItems[i])

            if (subMenuItems[i].id == id) {
                if(subMenuItems[i].children==undefined)
                arr.push(subMenuItems[i])

                return [subMenuItems[i],arr];
            }
            var found = getSubMenuItem(subMenuItems[i].children, id,arr);
            if (found) return found;
            if(subMenuItems[i].children)
            {
              arr.pop()
            }
        }
    }
};

var isParentNode = function (subMenuItems, id) {
  if (subMenuItems) {
      for (var i = 0; i < subMenuItems.length; i++) {
         
          if (subMenuItems[i].id == id) {
              
              if(subMenuItems[i].children==undefined)
              {
                return false;
              }
              else 
              {
                return true;
              }
          }
          var found = isParentNode(subMenuItems[i].children, id);
          if (found) return found;
          
      }
  }
};
  const renderTree = (nodes) => ( 
        <TreeItem key={nodes.id}  nodeId={nodes.id} label={ReactHtmlParser(nodes.name)}>
              {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
  
  );
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setselectedData] = useState([]);

  const handleToggle = (event, nodeIds) => {
 
     { 
    
       let newnodeIds =[]
       if(nodeIds[0]!=undefined)
       {
           newnodeIds.push(nodeIds[0]);
       }
       // this tree view works based on ids.... using L,G,S - 1,2,3 resp in indexes
       let selectedType='L';
       let selectedTypeNum = nodeIds[0]?.slice(0,1);
       if(nodeIds[0]?.includes('G'))
        {
            selectedType='G';
            
        } 
       if(nodeIds[0]?.includes('S'))
        {
            selectedType='S'
        }
       for(let i=1;i<nodeIds.length;i++)
       {
          if(nodeIds[i]?.includes(selectedType) || nodeIds[i]?.slice(0,1)!=(selectedTypeNum))
          {
              
          }
          else
          {
            newnodeIds.push(nodeIds[i]);
          }
       }
       setExpanded(newnodeIds);
       console.log('Expanded',newnodeIds);
      // handleSelect(null,newnodeIds[0]);

        let arr=[];
        let sel = getSubMenuItem(data,newnodeIds[0],arr);
    
       setSelected(nodeIds);
        if(sel?.length>=1)
            setselectedData(sel[1]);
          else
          {
              setselectedData([]);
          }

     }
  };

  const handleSelect = (event, nodeIds) => {
  // handleSelect event of library returns same ids for both selecting and unselecting. Hence put this handleselect code in handletoggle , to display none/all if we unselect
     
    console.log('handleSelect',nodeIds);
     {
        let arr=[];
        let sel = getSubMenuItem(data,nodeIds,arr);
        setSelected(nodeIds);
        if(sel?.length>=1)
            setselectedData(sel[1]);

        if(nodeIds.includes('0ALL'))
            {
               setExpanded([])
            }
     }


  };
 
  function handleClick(event,id) {
    event.preventDefault();
    let arr=[]
    let sel = getSubMenuItem(data,id,arr)
    setselectedData(sel[1]);
    setSelected(id);
    let isParent=isParentNode(data,id,arr)
    if(isParent)
    {
      let exp=expanded.slice(1,expanded.length)
      setExpanded([...exp])
    }
  }
  const [anchor, setanchor] = useState(false);
  const toggleDrawer = (open) => (event) => {
    //console.log(open,event.type,event.key)
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setanchor(open);
  };

  const GradewiseSubjects = useSelector(state => state.GetGradewiseSubjectsReducer);
  
  useEffect(() => {
      let examdata = formatExamData();
      setData([...examdata])
  }, [GradewiseSubjects]);

  function formatExamData ()
  {
    let ExamData = [
      {
        "backendId" : 0 ,
        "children" : [] ,
        "id" : "0ALL" ,
        "name" : "All Languages"
      }

   ]
    for(let k=0;k<GradewiseSubjects?.length;k++)
    {
 
      ExamData[k+1]={"id":(k+1)+"L","name":GradewiseSubjects[k].language,"children":[],"backendId":GradewiseSubjects[k].langId}

      for(let i=0;i<GradewiseSubjects[k]?.grades?.length;i++)
      {
          let obj = {}
          obj.id=(k+1)+""+(GradewiseSubjects[k].grades[i].gradeID)+"G";
          obj.name=GradewiseSubjects[k].grades[i].grade;
          obj.backendId=GradewiseSubjects[k].grades[i].gradeID;
          obj.children=[];
          gradeList.push(obj.name)
          for(let j=0;j<GradewiseSubjects[k].grades[i].testSubjects.length;j++)
          {
              let cobj={};
              cobj.id=(k+1)+""+(GradewiseSubjects[k].grades[i].gradeID)+""+GradewiseSubjects[k].grades[i].testSubjects[j].id+"S";
              cobj.backendId=GradewiseSubjects[k].grades[i].testSubjects[j].id;
              cobj.name=GradewiseSubjects[k].grades[i].testSubjects[j].subjectName;
              cobj.language=GradewiseSubjects[k].grades[i].grade;;
              obj.children.push(cobj);
              subjectList.push(cobj.name)
          }
          ExamData[k+1].children.push(obj)
      }

    }
    generateColor(); //Generating random unique colors
    console.log('FORMAT EXAM DATA')
    return ExamData;
  }
  

  const theme = createMuiTheme();
  const BreadcrumbsComp = () => {
    return <div className="col-9" style={{marginTop:window.screen.width<770?'7vh':'0'}}>
    <div style={{marginTop:'10px'}}>
           
        <div style={{background:'silver',marginBottom:'20px',width:'100%'}} >
            <Breadcrumbs separator="›"  aria-label="breadcrumb">
              {
                 selectedData.map((el,idx)=>
                          <>
                              {
                                idx==selectedData.length-1 ?
                                <Typography color="textSecondary" key={idx} >
                                      { ReactHtmlParser(el.name) }
                                </Typography>
                                :
                                <Link style={{textDecoration:'none'}} key={idx} color="inherit" to="/" 
                                onClick={(e)=>handleClick(e,el.id)}>
                                      { ReactHtmlParser(el.name) }
                                </Link>
                              }
                          </>
                          
                  )
              }
              </Breadcrumbs>
        </div>
    </div>
     
</div>
  }
    return (
        <div style={{top:'20vh'}} className="container-fluid" >
            <div className="row">
              {
              window.screen.width >=770 ?
              <>
              <TreeView style={{position:'fixed',overflow:'auto',height:'80vh',width:'200px'}} className={window.screen.width<770?classes.treeviewmobile+'':classes.treeview+' col-md-2'} 
                  expanded={expanded}  selected={selected}
                  onNodeToggle={handleToggle}  
                  onNodeSelect={handleSelect}
                  defaultExpandIcon={<svg className="d-flex justify-content-center" xmlns="http://www.w3.org/2000/svg" 
                  width="16" height="16" fill="currentColor"viewBox="0 0 16 16" focusable="true" aria-hidden="true">
                  <rect width="4" height="16" x="6" y="1" rx="1"/><path d="M1.5 14a.5.5  1a.5.5  "/></svg>}
                defaultCollapseIcon={<span style={{width:'100%'}} ><RemoveSharpIcon /></span>}
                defaultExpandIcon={<span style={{width:'100%'}}><ChevronRightIcon /></span>}  
                > 
                  {
                      data.map((nodes)=>renderTree(nodes))
                  }   
              </TreeView>
              <div style={{marginLeft:'200px'}} className="col-10">
                    <div  > {BreadcrumbsComp()} </div>
                    <AllTests testType={testType} selectedData={selectedData} subjectListColors={subjectListColors} gradeListColors={gradeListColors}  />
              </div>
              </>
              :
              <div>
                  <div style={{position:'fixed',background:'#232F3E',height:'7vh',top:'15vh',left:0,right:0,
                  width:'100%',alignItems:'center',display:'flex',fontWeight:'bolder',zIndex:2500,padding:'10px'}}> 
              
                      <div className="d-flex flex-row justify-content-start" style={{fontSize:'18px',paddingLeft:'20px',color:'white',width:'100%'}}>
                          <div onClick={toggleDrawer(true)}>
                            <span  style={{marginLeft:'3px'}}> Languages &nbsp; <i className="fa fa-chevron-circle-down"></i></span>
                          </div>
                      </div>
                  </div>
                  
                  <Drawer anchor={'left'} open={anchor} style={{zIndex:3000,marginTop:'15vh'}} onClose={toggleDrawer(false)}>
                 
                  <div
                      className={clsx(classes.list, {
                        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                      })}
                      role="presentation"
                      // onClick={toggleDrawer(false)}
                      // onKeyDown={toggleDrawer(false)}
                    >
                       <Button  variant="contained" startIcon={<CancelIcon />} style={{width:'100%',position:'fixed',zIndex:3001,justifyContent:'flex-start'}} onClick={toggleDrawer(false)}>Close</Button>
                      <TreeView className={window.screen.width<770?classes.treeviewmobile+'':classes.treeview+' col-lg-2'} 
                          onNodeToggle={handleToggle}
                          expanded={expanded}  selected={selected}
                          onNodeSelect={handleSelect}
                          defaultExpandIcon={<svg className="d-flex justify-content-center" xmlns="http://www.w3.org/2000/svg" 
                          width="16" height="16" fill="currentColor"viewBox="0 0 16 16" focusable="true" aria-hidden="true">
                          <rect width="4" height="16" x="6" y="1" rx="1"/><path d="M1.5 14a.5.5  1a.5.5  "/></svg>}
                        defaultCollapseIcon={<span style={{width:'100%'}} ><RemoveSharpIcon /></span>}
                        defaultExpandIcon={<span style={{width:'100%'}}><ChevronRightIcon /></span>}  
                        > 
                          {
                              data.map((nodes)=>renderTree(nodes))
                          }   
                      </TreeView>
                    </div>
                  </Drawer>
                  <div>
                        {BreadcrumbsComp()}
                        <AllTests testType={testType} selectedData={selectedData} subjectListColors={subjectListColors} gradeListColors={gradeListColors} />
                  </div>
              </div>
              }
              
              
            </div>
        </div>
    )
}

export default Exams
