import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };


export const CustomDropDown = (uniqueDatas,filtername,filtersSelected,filters) => {
   return <ReactSelect
    options={uniqueDatas[filtername]}
    isMulti
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    components={{
        Option
    }}
    onChange={(sel)=>filtersSelected(sel,filtername)}
    allowSelectAll={true}
    value={filters[filtername]}
    />
} 