import React from 'react';
import './CompanyName.css'

function CompanyName() {
  return <div className='compName'>
      Do Mock Exam
  </div>;
}

export default CompanyName;
