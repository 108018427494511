import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';


export async function registerTestAPI(action)
{
    let requestBody = action.payload;
    let http = `${ApiData.BaseApiLink}/account/register`;
    try {
        const res = await axios.post(http, requestBody,
            { headers: { Authorization: 'Bearer ' + getCookie('domockexamToken'), "Content-Type": "application/json" } });
        console.log("Successfully Registered");
        return res.data;
    } catch (err) {
        console.log('err-registerTestAPI:', err);
        return err;
    }
}

export function* registerTestAPIWorkerSaga(action)
{
    try{
            const Result = yield call(registerTestAPI,action);
            console.log('REGISTER_USER',Result);
            if(Result.result==false)
            {
                yield put({type:'ERROR_REGISTER_USER',payload:Result})
            }
            else
            yield put({type:'REGISTER_USER',payload:Result})
    }
    catch(e)
    {

    }
}

export function* registerTestAPISaga()
{
    yield takeEvery('REGISTER_USER_REQUESTED',registerTestAPIWorkerSaga)
}