import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';
//NEED TO CHECK THE yeild put 
function getGradewiseSubjectsFromAPI(action)
{
    let http = `${ApiData.BaseApiLink}/StudentTest/GetGradeWiseSubjects`;
    http = http+'?Grades='+action.gradeId;
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log('err-GetSubjects:',err);
    })
}

function* getGradewiseSubjectsWorkerSaga(action)
{
    try{
            const Subjects = yield call(getGradewiseSubjectsFromAPI,action);
            //console.log('GetGradeWiseSubjects---SAGA',Subjects);
            yield put({type:'GET_SUBJECTS',payload:Subjects.subjects});
    }
    catch(e)
    {

    }
}

export function* GetGradewiseSubjectsSaga()
{
    yield takeEvery('GET_SUBJECTS_REQUESTED',getGradewiseSubjectsWorkerSaga)
}