import React, { useEffect, useState } from 'react'
import './Qp.css';
//import Parser from 'html-react-parser';
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import DynamicComponent from './DynamicComponent'

function OneWordAnswer(props) {
    
    let {el,index,qusID,isResult,Results,sectionID,isCorrectAnswers} = props;
    index = Number(index)
    const [answers, setanswers] = useState([]);
    const [Question, setQuestion] = useState(el);
    let [dropCompIndex, setdropCompIndex] = useState(0);

    

    function getInputValue(e,id)
    {
       // console.log('getInputValue',id,e.target.value,answers)
        answers[id]=e.target.value;
        setanswers([...answers]);
        let obj={index:index,qusId:qusID,selectedAnswer:answers,qusType:'One word answer',lastUpdatedSectionIndex:sectionID}
        props.saveAnswersToStore(obj);
    }

    useEffect(() => {

        if(props.answersFromStore[index])
        {
            setanswers(props.answersFromStore[index].selectedAnswer)
        }
        else
        {
            for(let i=0;i<el.options.length;i++)
            {
            answers[i]='';
            }
            setanswers([...answers]);
        }
    }, []);

const added =[]
function renderData(){ 
    let d = Parser(Question.questionName);
    //console.log(d) ;
    renderDrag(d) ;  
}


const renderDrag = (d) => {
    for(let i=0;i<d.length;i++)
    {
        let temp=d[i];
        if(!(Array.isArray(temp)))       //object
        // while(temp)
        if(temp)
        {
            if(temp.type=='h1')
            {
                console.log('H1 - DROP IN ONE WORD ANSWER')
                added.push('DROP')
            }
            else
            {
              //  if(temp?.props==null)
                {

                    added.push(temp);
                }
            }
            temp = temp?.props?.children;
        }
        else
        {
            renderDrag(temp?.props?.children)
        }
    }
    
}

function renderHTML()
{
    renderData();
   // console.log('added',added)
    
    return (<div>
        {
            added.map((html,i)=>
                <>
                        {
                            html=='\n'?
                            <></>
                            :
                            html=='DROP'?
                            <>
                            {
                                DroppableComp(dropCompIndex++)
                            }

                            </>
                            :
                            html
                        }
                </>
            )
        }
    </div>)
}

const DroppableComp = (ind) => {
    ind = Number(ind);
    return <>
                <input type="text" disabled={isResult} spellCheck="false" id={ind} 
                value={!isCorrectAnswers?props.answersFromStore[index]?.selectedAnswer[ind]:el.options[ind].option}
                className={'typein '+((isResult  && Results[ind]?.isCorrect) ? ' bggreen ' : isResult && Results[ind]?.isCorrect==false ?' bgred ' : ' none ')}
                onChange={(e)=>{getInputValue(e,ind);}}  placeholder={!isResult && "Type here"} />
    </>
}


  
    return (
        <div>
                <div>
                    <div style={{marginLeft:'20px',marginRight:'20px'}}>
                        <h5>{Parser(Question.instruction)}</h5>
                        
                        <div style={{lineHeight:'2.5',overflow:'auto',width:'100%'}}>
                                {/* {renderHTML()} */}
                                {/* {
                                    htmlParser.parseWithInstructions(Question.questionName, 
                                        isValidNode, processingInstructions)
                                } */}
                                {
                                    <DynamicComponent questionName={Question.questionName} DroppableComp={DroppableComp} />
                                }
                        </div>
                    </div>
                    
                   
                </div>
             
        </div>
    )
}

//export default OneWordAnswer


const mapStateToProps = state => {
    return {
       answersFromStore:state.AnswersReducer,
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
        saveAnswersToStore:(json) => dispatch({type:'SET_ANSWERS',payload:json})
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(OneWordAnswer);