import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./MultiStepProgressBar.css";

export const MultiStepProgressBar = (props) => {

  const steps = Array.from(Array(props.numberOfSteps), (_, index) => index + 1);

  return (
    <ProgressBar
        percent={((props.step) * 100) / (props.numberOfSteps-1)}
        filledBackground="indigo"
      >
        {
          steps.map((el,ind)=>
              <Step key={el} transition="scale">
              {({ accomplished, index }) => (
                <div
                  className={`step ${accomplished ? "completed" : null}`}
                >
                  {el}
                </div>
              )}
            </Step>
          )
        }
       
      </ProgressBar>
  )
};