import React,{useState,useEffect,useRef} from 'react';
import './Register.css';
import { BrowserRouter as Router , Switch, Route,Link,useHistory} from 'react-router-dom';
import Home from './Home';
import { useDispatch, useSelector } from 'react-redux';
import UserRegistrationForm from './UserRegistrationForm';
import useForm from './useForm';
import UserRegistrationFormSubmit from './UserRegistrationFormSubmit';
import data from './UserForm.json';
import Alert from 'react-bootstrap/Alert';
import { useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';

function Register() {
    
    const history = useHistory();
    const [UserType, setUserType] = useState({role:'',roleRequested:'',roleName:''});
    const UserLogin = useSelector(state => state.LoginReducer);
    const initialData1 = JSON.parse(JSON.stringify(data));
    const initialData2 = JSON.parse(JSON.stringify(data));
    const [ formData, handleInputChange, handleValidate, resetFormDataHandler ] = useForm(initialData1);
    const [ formData2, handleInputChange2, handleValidate2 , resetFormDataHandler2 ] = useForm(initialData2);
    const RegistrationResult = useSelector(state => state.RegisterReducer);
    const dispatch = useDispatch();
 

    useEffect(()=>{
        return () => {
            dispatch({type:'CLEAR_REGISTER_USER'})
        }
    },[])
  
    const changeUserTypeHandler = (e) => {
        if(UserType != "")
        {
            resetFormDataHandler({...initialData1});
            resetFormDataHandler2({...initialData2})
        }
        UserType.roleName=e.target.value;
        if(UserType.roleName == "Major")
        {
            UserType.role = 7; UserType.roleRequested = 7; 
        }
        if(UserType.roleName == "Teacher")
        {
            UserType.role = 4; UserType.roleRequested = 4; 
        }
        setUserType({...UserType});
    }
    
    const memoizedGrade = useMemo(() => 
          {
            if(formData['grade'].result!=18 && formData['grade'].result!='')
            {
                UserType.role = 2;//parent
                UserType.roleRequested = 6; //minor
                UserType.roleName = "Parent";
                setUserType({...UserType});
            }
            else if(formData['grade'].result==18)
            {
                UserType.role = 7; UserType.roleRequested = 7; 
                UserType.roleName = "Major";
                setUserType({...UserType});
            }
          },
    [formData.grade]);


    

    const ResultComponent = (RegistrationResult) => {

        return(
            (RegistrationResult?.result == false) ? 
            <Alert key={'danger'} variant={'danger'}>
                Please check for the following errors 
                <hr></hr>
                {RegistrationResult?.message || 'Sorry for your inconvinience! Some error occurred'}
            </Alert>
            :
            (RegistrationResult?.result == true) ? 
            <>
            <Alert data-testid='testid-success'  key={'success'} variant={'success'}>
                {RegistrationResult?.message}
                <br></br>
                Please note your unique User ID : <b>{RegistrationResult?.response?.userId}</b>
                <br></br>
                Please check your email for activation link
                <hr></hr>
                <Link to='/login'>Please login using this link after email activation</Link>
                <br></br>
                <Link to='/exams'>Please explore our exams here</Link>
            </Alert>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <p></p>
                <div className=""><i>Want to register as a different user again?</i></div>
                <button className="btn btn-warning lrbutton" onClick={()=>{  history.push('/'); history.replace('/register');}}>Register</button>
                <p></p>
            </div>
            </>
            :
            ''
        )
      }

    return (
        <div data-testid='testid-register' className="container-fluid">
            
            <div className="row" >
                <Home  />
                <> 
                {
                
                !(Object.keys(UserLogin).length>0 && ((UserLogin.value?.token!='undefined')) && UserLogin.result!=false) 
                &&
                <div  className="col-lg-9 d-flex flex-column justify-content-center align-items-center" >
                {RegistrationResult?.result != true &&
                    <>
                    <div className={UserType.roleName==""?'mycard':''} >
                        <h3 className="d-flex justify-content-center align-items-center">Register</h3>
                    
                        <div className="smalltext"  >
                                <h6 className="px-3 smalltext"> Are you a Student/Teacher?  </h6>
                                <div className=" px-3 paddedInput" >
                                    <select value={UserType.roleName} className="form-select smalltext mb-2" aria-label="Default select example"
                                    onChange={(e)=>{changeUserTypeHandler(e)}} >
                                        <option value="">Select User Type</option>
                                        <option value="Major">Student/Parent</option>
                                        {/* <option value="Parent">Parent + Minor Student</option> */}
                                        <option value="Teacher">Teacher</option>
                                       
                                    </select>
                                </div>
                        </div>
                    </div>
                  
                    {
                        UserType.roleName != '' &&
                        <>
                            <div className='d-flex flex-row' style={{width:'100%'}}>
                            {
                                UserType.roleName == "Major" ?
                                <UserRegistrationForm formId={UserType.role} 
                                formData={formData} handleInputChange={handleInputChange} handleValidate={handleValidate} 
                                role={UserType.role} roleName={UserType.roleName} />
                                :
                                UserType.roleName == "Parent" ?
                                <>
                                     <UserRegistrationForm formId={6} 
                                    formData={formData} handleInputChange={handleInputChange} handleValidate={handleValidate} 
                                    role={6} roleName={'Minor'} />
                                   
                                    <UserRegistrationForm formId={2} 
                                    formData={formData2} handleInputChange={handleInputChange2} handleValidate={handleValidate2} 
                                    role={2} roleName={'Parent'} />
                                   
                                </>
                                :
                                <UserRegistrationForm formId={UserType.role} 
                                formData={formData} handleInputChange={handleInputChange} handleValidate={handleValidate} 
                                role={UserType.role} roleName={UserType.roleName} /> //Teacher/Tutor/...
                            }
                           
                              
                            </div>
                            {
                                UserType.roleName !== "Parent" ?
                                <UserRegistrationFormSubmit role={UserType.role} roleRequested={UserType.roleRequested} 
                                roleName={UserType.roleName} StudentData={formData2} data={formData} validate={handleValidate}  validate2={handleValidate2} />
                                :
                                <UserRegistrationFormSubmit role={UserType.role} roleRequested={UserType.roleRequested} 
                                roleName={UserType.roleName} StudentData={formData} data={formData2} validate={handleValidate}  validate2={handleValidate2} />
                                
                            }
                        </>
                    }

                    </>
                }

                <div className="mt-2">
                {ResultComponent(RegistrationResult)}
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center">
                        <p></p>
                        <div className=""><i>Already have an account?</i></div>
                        <button className="btn btn-warning lrbutton" onClick={()=>history.push('/login')}>Login</button>
                        <p></p>
                </div>
                   
                </div>
                           
                }
                </>
            </div>
         
        </div>
    )
}

export default Register


/*

 "options":[
            {"key": "1", "value": "1","NotAllowedUsers":["Major"]},
            {"key": "2", "value": "2","NotAllowedUsers":["Major"]},
            {"key": "3", "value": "3","NotAllowedUsers":["Major"]},
            {"key": "4", "value": "4","NotAllowedUsers":["Major"]},
            {"key": "5", "value": "5","NotAllowedUsers":["Major"]},
            {"key": "6", "value": "6","NotAllowedUsers":["Major"]},
            {"key": "7", "value": "7","NotAllowedUsers":["Major"]},
            {"key": "8", "value": "8","NotAllowedUsers":["Major"]},
            {"key": "9", "value": "9","NotAllowedUsers":["Major"]},
            {"key": "10", "value": "10","NotAllowedUsers":["Major"]},
            {"key": "11", "value": "G.C.E O/L","NotAllowedUsers":["Major"]},
            {"key": "12", "value": "G.C.E A/L","NotAllowedUsers":["Major"]},
            {"key": "18", "value": "Others ( or Above 18 Year of age )","NotAllowedUsers":["Minor"]}
          ]          
          ,

*/