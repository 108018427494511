let initialState = []

const GetSubjectsByGradeReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_SUBJECTS_BYGRADE':
            {
                state=action.payload;
                return [...state];
            }

        default:
             return state;
    }
}

export default GetSubjectsByGradeReducer;