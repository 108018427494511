import React, { useImperativeHandle, useState } from 'react'
import './pagination.css';

const Pagination = React.forwardRef((props, ref) => 
{

  const {PaginationLength,sendCurrentPageData} = props;
  const [pageNumber, setPageNumber] = useState(1);

  const callCurrentPageData = (pageNumber) => {
    setPageNumber(pageNumber);
    sendCurrentPageData(pageNumber);
  }

  useImperativeHandle(ref, () => ({
    setCurrentPageNumberToDefault() {
        setPageNumber(1);
    }
  }),[])

  const next = () => {
    if(pageNumber<PaginationLength)
    {
        callCurrentPageData(pageNumber+1)
    }
  }

  const previous = () => {
    if(pageNumber>1)
    {
        callCurrentPageData(pageNumber-1)
    }
  }

  return (
    <div>
        {
            PaginationLength >=1 &&
            <div className='d-flex justify-content-center mb-5 mt-5'>
                <div className='paginate'>
                        <span className={'cursor-pointer '+(pageNumber==1 && 'disabledPage')}  onClick={()=>previous()}>
                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i> &nbsp; | 
                        </span>
                        <>
                            {
                                Array.from({length: PaginationLength}, (_, i) => i + 1).map((page)=>
                                <span key={page} className={(page==pageNumber?'activePage':'')+' pageNumber'} onClick={()=>callCurrentPageData(page)} >
                                    {page}
                                </span>
                                )
                            }
                        </>
                        <span className={'cursor-pointer '+(pageNumber==PaginationLength && 'disabledPage')} onClick={()=>next()}>
                            |  &nbsp;  <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
                        </span>
                </div>
            </div>
        }
    
    </div>
  )
}
)

export default Pagination
