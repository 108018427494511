//Not yet used. Created this to resolve code duplication. Merging Student , Teacher , Tutor , Parent to one 
import React,{useState,useRef, useEffect, useCallback} from 'react'
import PasswordView from '../HelperComps/PasswordView';

function UserRegistrationForm(props) {
  
  const {role,roleName,formData, handleInputChange, handleValidate,formId} = props;
  
  const Passref = useRef()

  return (
    <div data-testid={'testid-'+formId} key={formId} className="row justify-content-center" style={{width:'100%'}}>
      <div className="col-12">
        <div  style={{backgroundColor:'#F5F5F5',border:'1px solid #ced4da'}}>
          <div className="mb-2" style={{backgroundColor:'#0D6EFD',color:'white',paddingLeft:'10px',width:'100%'}}>
            {roleName=='Minor' || roleName=='Major' ? 'Student' : roleName} Registration Form
          </div>
          <form id={formId}>
            {
              Object.values(formData).map( (item,index) => 
                !item.NotAllowedUsers?.includes(roleName) &&
                
                <div className="row smalltext" id={'RegForm'+formId+item.value} key={'RegForm'+formId+item.value+index}> 
                    <h6 className="px-3 smalltext">{item.name} : {item.required && <span className="px-1" style={{color:'red'}}>*</span>}</h6>
                    <div className="px-3 paddedInput mb-2">
                      { 
                        item.type=='text' || item.type=='email' || item.type=='number' ?
                        <input className="mb-1 form-control smalltext" type={item.type} value={formData[item.value]?.result}
                                onChange={(e)=>{handleInputChange(e,item.validationFunction)}} 
                                name={item.value} placeholder={'Enter '+item.name} /> 
                        : item.type =='password'?
                        <span style={{display:'flex',justifyContent:'space-around'}}>
                          <input autoComplete="off" ref={Passref} className="mb-1 form-control smalltext" type={item.type} value={formData[item.value]?.result}
                           onChange={(e)=>{handleInputChange(e,item.validationFunction)}} 
                                name={item.value} placeholder={'Enter '+item.name} />   
                          <PasswordView Passref={Passref} />
                        </span>
                        : item.type=='radio'?
                          item.options.map((option) =>
                            <div className="form-check form-check-inline">
                                <input autoComplete="off" className="form-check-input" type={item.type} name={item.value} 
                                id={'Radio'+formId+option} key={'Radio'+formId+option} value={option}
                                onChange={(e)=>{handleInputChange(e,item.validationFunction)}} />
                                <label className="form-check-label smalltext"  htmlFor={'Radio'+formId+option}>{option}</label>
                            </div>
                          )
                        : item.type=='select' ?
                        <>
                            {
                              item.value == 'district' ?
                              <>
                                <input className="mb-1 form-control smalltext" list={'DataList'+item.value+formId} type="text" value={formData[item.value]?.result} 
                                name={item.value} 
                                onChange={(e)=>{handleInputChange(e)}} placeholder={item.name} />
                                <datalist id={'DataList'+item.value+formId}>
                                <option value="" disabled selected>{item.name}</option>
                                {
                                    item.options.map((optionObj)=>
                                    !optionObj.NotAllowedUsers?.includes(roleName) && 
                                    <option key={optionObj.key} value={optionObj.key}>
                                      {optionObj.value}
                                    </option>
                                    )
                                }
                              </datalist>
                              </> :
                              <>
                              <select className="mb-1 form-control smalltext" value={formData[item.value]?.result} 
                                name={item.value} 
                                onChange={(e)=>{handleInputChange(e)}} placeholder={item.name} >
                                  <option value="" disabled selected>{item.name}</option>
                                   {
                                    item.options.map((optionObj)=>
                                    !optionObj.NotAllowedUsers?.includes(roleName) && 
                                    <option key={optionObj.key} value={optionObj.key}>
                                      {optionObj.value}
                                    </option>
                                    )
                                }
                              </select>
                              </>
                            }
                        </>
                        :
                        ''
                      }
                        <span className="err">{formData[item.value]?.validationError}</span>
                    </div>
                </div>
                
            )
            }
          </form>
        </div>
        </div>
    </div>
  )
}

export default UserRegistrationForm
