import React, { useState } from 'react'

function District(props) {

    const [Districts, setDistricts] = useState(['Ampara','Anuradhapura','Badulla','Batticaloa','Colombo','Galle','Gampaha','Hambantota','Jaffna',
            'Kalutara','Kandy','Kegalle','Kilinochchi','Kurunegala','Mannar','Matale','Matara','Monaragala','Mullaitivu','Nuwara Eliya',
        'Polonnaruwa','Puttalam','Ratnapura','Trincomalee','Vavuniya'])
    return (
        <div className="row smalltext">
                    <h6 className="px-3 smalltext"> District Name :</h6>
                    <div className="px-3 paddedInput" >
                    <input className="mb-1 form-control smalltext" list="districts" type="text" value={props.Data.district} name="district" 
                        onChange={(e)=>{props.Data.district=e.target.value;
                            props.setData({...props.Data})}} placeholder="Select District" />
                            <datalist id="districts">
                            {
                                Districts.map((dis)=><option>{dis}</option>)
                            }
                            </datalist>
                    </div>
                </div>
    )
}

export default District
