
import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function EmailConfirmationAPI(action)
{
    const requestBody = action.payload 
    let http = `${ApiData.BaseApiLink}/account/ConfirmEmail?Email=${requestBody.email}&&Token=${requestBody.token}`;

    return axios.get(http)
    .then((res)=>{ 
        console.log("Confirm Email Success");
        return res;
    })
    .catch((err)=>{
        console.log('err-EmailConfirmationAPI:',err);
    })
}

function* EmailConfirmationAPIWorkerSaga(action)
{
    try{
            const Result = yield call(EmailConfirmationAPI,action);
            console.log('CONFIRM_EMAIL Result',Result);
            yield put({type:'CONFIRM_EMAIL',payload:Result})
    }
    catch(e)
    {

    }
}

export function* EmailConfirmationAPISaga()
{
    yield takeEvery('CONFIRM_EMAIL_REQUESTED',EmailConfirmationAPIWorkerSaga)
}