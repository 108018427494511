let initialState = {QuestionsByTest:undefined,testDetails:undefined}

function shuffle(sourceArray) {
    for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(Math.random() * (sourceArray.length - i));
        var temp = sourceArray[j];
        sourceArray[j] = sourceArray[i];
        sourceArray[i] = temp;
    }
    return sourceArray;
}

const GetQuestionsByTestReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_QUESTIONS_BY_TEST':
            {
                state.QuestionsByTest=action.payload.result;
                let shuffleTypeId = action.payload.testDetails.shuffleTypeId;
                for(let k=0;k<state.QuestionsByTest.length;k++)
                {
                    //Shuffling questions each time a test is opened
                    //Shuffling might(if clicked from exams page) or might not happen ==> should be changed to 
                    //if(state.testDetails?.subjectName=='Scholarship' || (state.testDetails?.language != 2)  )
                    if(shuffleTypeId==10 || shuffleTypeId==5)
                        state.QuestionsByTest[k].questions = shuffle(state.QuestionsByTest[k]?.questions);

                for(let i=0;i<state.QuestionsByTest[k]?.questions.length;i++)
                {
                    let el = state.QuestionsByTest[k]?.questions[i];
                    //console.log('QUS ID',el.qusID)
                    el.instruction="";
              
                    {
                        let str=el.questionName; 
                        
                        if(el.qusType=="Match the following")
                        {
                            let correctOpts = JSON.parse(el.correctOption);
                            let optsRight = [];
                            for(let opt in correctOpts)
                            {
                                optsRight.push(Object.keys(correctOpts[opt])[0])
                            }
                            
                            let opts = el.options;
                            for(let i=0;i<opts.length;i++)
                            {
                                opts[i].left = opts[i].option;
                                opts[i].right = optsRight[i];
                                //OptionsLeft[i]= opts[i];
                            }

                            el.options=opts;
                        }

                        el.customOptions=[...el.options];

                        let extraregex = /\[extra:"(.*?)\"\]+/g;
                        var extramatches = extraregex.exec(str);  //for multiple use match

                        if(extramatches!=null && extramatches.length>0)
                        {
                            let exid = el.customOptions.length;
                            let exoptions = extramatches[1]?.split(";");
                            for(let ex=0;ex<exoptions.length;ex++)
                            {
                                el.customOptions.push({
                                    "id": exid++,
                                    "option": exoptions[ex],
                                    "correctAnswer": null,
                                    "isCorrect": false,
                                    "position": el.customOptions.length,
                                    "left":exoptions[ex],
                                    "right":exoptions[ex]
                                }); 
                            }
                            
                        }
                        
                        
                       // console.log(extramatches,'extramatches');
                        str = str.replace(extraregex, '');

                        //////////////////////

                        if(el.instruction==undefined ||el.instruction==""){

                            const instregex = /\[Instruction:"(.*?)\"\]+/g;
                            var matches = instregex.exec(str);
                            str = str.replace(instregex, '');
                            if(matches!=null && matches.length>0)
                            el.instruction = matches[1];
                
                        }
            
                       
                        const regex = /{[^{}]+}/g;
                        str = str.replace(regex, '<h1 style="display:inline-block;"></h1>');
                        
                        //[one word answer - formatting
                        //
                        if(el.qusType=='One word answer' || el.qusType=='Gap Filling' || el.qusType=='Dropdown')
                        {
                            var index = -1;

                            // str = str.replaceAll('<p>&nbsp;</p>', '');
                            // str = str.replaceAll('<p></p>', '');
                            // str = str.replaceAll('<p>', '<br />');
                            // str = str.replaceAll('</p>', '');

                            str = str.replace(/<h1 style="display:inline-block;"><\/h1>/g, function () {
                                index++;
                                return `<droppablecomp data-id=${index} refresh="true" ind=${index} ></droppablecomp >`;
                            });
                        }
                        //else
                        {
                            //str=str.split('<p>&nbsp;</p>').join(""); 
                            //str = str.replaceAll('style="line-height: 1;"','');
                            //str = str.replaceAll('<h1 style="display:inline-block;"></h1>','');
                            str = str.replaceAll('<p>&nbsp;</p>', '');
                            str = str.replaceAll('<p></p>', '');
                            str=str.split('<br />').join("");
                        }
                        //]
            
                        let opts = el.customOptions;
                        if(el.qusType!='Dropdown' && (shuffleTypeId==15 || shuffleTypeId==5))
                        opts = shuffle(opts);
                        el.customOptions = opts;
                        //console.log(el.customOptions);
                        
            
                        el.questionName=str;
                        el.formatted=true;
                    }
                }
            }
                return {...state};
            }

        case 'CLEAR_QUESTIONS_BY_TEST':
            {
                state = {QuestionsByTest:undefined,testdetails:undefined}
                return {...state};
            }
        default:
             return {...state};
    }
}

export default GetQuestionsByTestReducer;