import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function getTopicsBySubjectFromAPI(action)
{
    let http = `${ApiData.BaseApiLink}/v1/test/GetTopicsBySubjectId`;
    http = http+'?subjectId='+action?.payload?.subjectId;
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        //console.log('getTopicsBySubject API Success')
        return res.data;
    })
    .catch((err)=>{
        console.log('err-getTopicsBySubject:',err);
    })
}

function* getTopicsBySubjectWorkerSaga(action)
{
    try{
            const Topics = yield call(getTopicsBySubjectFromAPI,action);
            console.log('getTopicsBySubject---SAGA',Topics);
            yield put({type:'GET_TOPICS_BY_SUBJECT',payload:Topics?.response});
    }
    catch(e)
    {

    }
}

export function* getTopicsBySubjectSaga()
{
    yield takeEvery('GET_TOPICS_BY_SUBJECT_REQUESTED',getTopicsBySubjectWorkerSaga)
}