let initialState = []

const GetTopicsBySubjectReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_TOPICS_BY_SUBJECT':
            {
                state=action.payload;
                if(state.length<=1) return new Array();
                return [...state.slice(1)];
            }

        default:
             return state;
    }
}

export default GetTopicsBySubjectReducer;