import React, { useState, useRef, useEffect } from 'react';
import SearchbarDropdown from './SearchbarDropdown';
import './SearchbarDropdown.css'
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchStudents from './SearchStudents';

function SearchAndAddStudent() {
  
   
  return (
    <div className="App container mt-2 mb-3 ">
      <div>
        <h4 className='mt-4'> Search for a student </h4>
        {/* <SearchbarDropdown setChoosenStudentHandler={setChoosenStudentHandler} options={options} onInputChange={onInputChange} /> */}
        <SearchStudents  />
        <br />
        

      </div>
      
    </div>
  );

}

export default SearchAndAddStudent