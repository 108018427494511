export function pad2(number) {
    number = Number(number);
    return (number < 10 ? '0' : '') + number
}

export function getPageCode (payload){
    
    let code = payload.languageId+ '' + payload.gradeId +'' + payload.subjectId;
    if(payload.paginationQuery){
        code = payload.paginationQuery.pageNumber + '' + code;
    }
    if(payload.isTutorviewOnly){
        code =  'tutor_' + code;
    }
    return code;
  }