let initialState = [];

const StudentTeacherInvitationResponseReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'STUDENT_TEACHER_INVITATION_RESPONSE':
            {
                state = action.payload;
                return {...state};
            }
        case 'RESET_STUDENT_TEACHER_INVITATION_RESPONSE':
                {
                    state = [];
                    return {...state};
                }
        default:
             return {...state};
    }
}

export default StudentTeacherInvitationResponseReducer;