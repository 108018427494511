import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function getSubjectsByGradeFromAPI(action)
{
    let http = `${ApiData.BaseApiLink}/test/GetSubjectsByGrades`;
    http = http+'?Grades='+action?.payload?.gradeId;
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        //console.log('getSubjectsByGrade API Success')
        return res.data;
    })
    .catch((err)=>{
        console.log('err-getSubjectsByGrade:',err);
    })
}

function* getSubjectsByGradeWorkerSaga(action)
{
    try{
            const Subjects = yield call(getSubjectsByGradeFromAPI,action);
            console.log('getSubjectsByGrade---SAGA');
            yield put({type:'GET_SUBJECTS_BYGRADE',payload:Subjects?.response});
    }
    catch(e)
    {

    }
}

export function* getSubjectsByGradeSaga()
{
    yield takeEvery('GET_SUBJECTS_BYGRADE_REQUESTED',getSubjectsByGradeWorkerSaga)
}