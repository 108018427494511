import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


import { createStore,compose,applyMiddleware } from 'redux';
import allReducers from './Redux/Reducers';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Redux/Sagas';
const sagas= createSagaMiddleware();
const store = createStore(allReducers,applyMiddleware(sagas))
sagas.run(rootSaga);

// axios.interceptors.request.use(request => {
//   request.metadata = { startTime: new Date()}
//   console.log('INTERCEPTOR REQUEST',request);
//   return request;
// })

// axios.interceptors.response.use(response => {
//   response.metadata = { endTime : new Date()}
//   console.log('INTERCEPTOR RESPONSE',response);
//   return response;
// })


//const store = createStore(allReducers);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
          <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
