let initialState = [];

const SearchStudentReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'SEARCH_STUDENT':
            {
                state = action.payload;
                return {...state};
            }
        case 'RESET_SEARCH_STUDENT':
                {
                    state = [];
                    return {...state};
                }
        default:
             return {...state};
    }
}

export default SearchStudentReducer;