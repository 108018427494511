import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function getQuestionsByTestFromAPI(action)
{
    let http = `${ApiData.BaseApiLink}/StudentTest/GetQuestionsByTestId?testid=${action.payload.testid}&groupby=1`;
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        return {result:res.data,testDetails:action.payload.testDetails};
    })
    .catch((err)=>{
        console.log('err-GetQuestionByTestId:',err);
    })
}

function* getQuestionsByTestWorkerSaga(action)
{
    try{
            const Result = yield call(getQuestionsByTestFromAPI,action);
            //console.log('getQuestionsByTestWorkerSaga',Result);
            yield put({type:'GET_QUESTIONS_BY_TEST',payload:{result:Result.result.questions,testDetails:Result.testDetails}})
    }
    catch(e)
    {

    }
}

export function* getQuestionsByTestSaga()
{
    yield takeEvery('GET_QUESTIONS_BY_TEST_REQUESTED',getQuestionsByTestWorkerSaga)
}