let initialState = {};

const GetTestByIdReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case 'GET_TEST_BY_ID':
            {
                state=action.payload;
                return {...state};
            }
        default:
             return state;
    }
}

export default GetTestByIdReducer;