import AnswersReducer from './AnswersReducer'
import AllTestsReducer from './AllTestsReducer'
import GetQuestionsByTestReducer from './GetQuestionsByTestReducer'
import LoginReducer from './LoginReducer';
import RegisterReducer from './RegisterReducer'
import { combineReducers } from 'redux';
import ReportsReducer from './GetStudentTestReportReducer';
import GetCalculatedResultsReducer from './GetCalculatedResultsReducer';
import GetGradewiseSubjectsReducer from './GetGradewiseSubjectsReducer'
import SearchReducer from './SearchReducer'
import ResetPasswordReducer from './ResetPasswordRecuder'
import ResetStudentPasswordByStudentReducer from './ResetStudentPasswordByStudentReducer'
import GetStudentAssignedTestReducer from './GetStudentAssignedTestReducer'
import SaveStudentTestReducer from './SaveStudentTestReducer'
import EmailConfirmationReducer from './EmailConfirmationReducer'
import RegisterStudentReducer from './RegisterStudentReducer'
import SearchStudentReducer from './SearchStudentReducer'
import SendStudentTeacherInvitationReducer from './SendStudentTeacherInvitationReducer';
import StudentTeacherInvitationResponseReducer from './StudentTeacherInvitationResponseReducer'
import GetTestByIdReducer from './GetTestByIdReducer'
import RandomExamReducer from './RandomExamReducer';
import GetGradesByLangReducer from './GetGradesByLangReducer';
import GetSubjectsByGradeReducer from './GetSubjectsByGradeReducer';
import GetTopicsBySubjectReducer from './GetTopicsBySubjectReducer';
import GetSubTopicByTopicReducer from './GetSubtopicByTopicReducer';


const allReducers = combineReducers({
    AnswersReducer,
    AllTestsReducer,
    GetQuestionsByTestReducer,
    LoginReducer,
    RegisterReducer,
    ReportsReducer,
    GetCalculatedResultsReducer,
    GetGradewiseSubjectsReducer,
    SearchReducer,
    ResetPasswordReducer,
    ResetStudentPasswordByStudentReducer,
    GetStudentAssignedTestReducer,
    SaveStudentTestReducer,
    EmailConfirmationReducer,
    RegisterStudentReducer,
    SearchStudentReducer,
    SendStudentTeacherInvitationReducer,
    StudentTeacherInvitationResponseReducer,
    GetTestByIdReducer,
    RandomExamReducer,
    GetGradesByLangReducer,
    GetSubjectsByGradeReducer,
    GetTopicsBySubjectReducer,
    GetSubTopicByTopicReducer
})

export default allReducers;