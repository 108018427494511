import React, { useEffect, useState } from 'react'
import { FormItem } from './FormItem'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './GenerateExam.css';
import { useDispatch, useSelector } from 'react-redux';
import {getFormQuestions} from './FormData.js'
import { BrowserRouter as Router , Switch, Route,Link,useHistory} from 'react-router-dom';
import {MultiStepProgressBar} from './MultiStepProgressBar.js';
import Popup from '../HelperComps/Popup';
import useStateCallback from '../HelperComps/useStateCallback.js'
import RecaptchaComp from '../User/RecaptchaComp';

function MultiStepForm() {
    const [currentStep, setCurrentStep] = useStateCallback(0);
    const [FormQuestions, setFormQuestions] = useState( getFormQuestions());
    const [validationError, setValidationError] = useState(new Array(5).fill(false));
    const [answers, setAnswers] = useState({});
    const dispatch = useDispatch();
    const successMessage = 'Exam generated successfully.You can find your generated test under My Tests page.You will be redirected to My Tests page after you close the dialog.';
    
    const GeneratedRandomExam = useSelector(state => state.RandomExamReducer);
    const history = useHistory();
    const UserLogin = useSelector(state => state.LoginReducer);
    const GradesByLang = useSelector(state => state.GetGradesByLangReducer);
    const SubjectsByLang = useSelector(state => state.GetSubjectsByGradeReducer);
    const TopicsBySubject = useSelector(state => state.GetTopicsBySubjectReducer);
    const SubTopicsByTopic= useSelector(state => state.GetSubTopicByTopicReducer);
    const [showPopUp, setshowPopUp] = useState(false);
    const [popUpMsg, setPopUpMsg] = useState("");
    const [cache,setCache] = useState({'grades':{},'subjects':{},'topics':{},'subtopics':{}});
    const [cacheIDs,setCacheIDs] = useState({});
    const [Human, setHuman] = useState(false)

    const returnStateHandler = (clickedyes,clickedclose) => {
        setshowPopUp(clickedclose);
        if(popUpMsg==successMessage)
        {
            history.push(`/myTests`);
            dispatch({type:'RESET_RANDOM_EXAM'});
        }
    }

    useEffect(() => {
        if(GeneratedRandomExam.loading==false)
        {
            if(GeneratedRandomExam?.RandomExam?.description==-1)
            {
                setCurrentStep(2);
                setPopUpMsg("Exam name already exists. Please set a different exam name. ")
                setshowPopUp(true);
            }
            else if(GeneratedRandomExam?.RandomExam?.response?.length==0 || GeneratedRandomExam?.response?.length==0)
            {
                setCurrentStep(1);
                setPopUpMsg("Sorry! No questions for the selected grade and subject at the moment. Please select different grade and subject")
                setshowPopUp(true);
            }
            else
            {
                setPopUpMsg(successMessage)
                setshowPopUp(true);
            }
        }
    }, [GeneratedRandomExam])
    
    useEffect(() => {
        if(GradesByLang?.length>0)
        {
            //Form Select getting enabled before data is loaded! Need : enable select only after data is available
           {
                FormQuestions[1].items[1].disabled = false; 
                FormQuestions[1].items[1].options = GradesByLang;
                cache['grades'][cacheIDs.recentlyCalledLanguage] = GradesByLang;
                setFormQuestions([...FormQuestions]);
           }
        }
    }, [GradesByLang])
    
    useEffect(() => {
        if(SubjectsByLang?.length>0)
        {
            FormQuestions[1].items[2].disabled = false; 
            FormQuestions[1].items[2].options = SubjectsByLang;
            cache['subjects'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade] = SubjectsByLang;
            setFormQuestions([...FormQuestions]);
        }
    }, [SubjectsByLang])

    useEffect(() => {
        if(TopicsBySubject?.length>0)
        {
            FormQuestions[1].items[3].disabled = false; 
            FormQuestions[1].items[3].options = TopicsBySubject;
            cache['topics'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade+cacheIDs.recentlyCalledSubject] = TopicsBySubject;
            setFormQuestions([...FormQuestions]);
        }
    }, [TopicsBySubject])

    useEffect(() => {
       
        if(SubTopicsByTopic?.length>0)
        {
            FormQuestions[1].items[4].disabled = false; 
            FormQuestions[1].items[4].options = SubTopicsByTopic;
            cache['subtopics'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade+cacheIDs.recentlyCalledSubject+cacheIDs.recentlyCalledTopic] = SubTopicsByTopic;
            setFormQuestions([...FormQuestions]);
        }
    }, [SubTopicsByTopic])

    const saveAnswers = (value,category,currentItemIndex) => {
        let ans = {...answers,[category]:value};
        if(category=='language')
        {
            //FormQuestions[1].items[1].disabled = false; 
            ans['gradeID'] = 'undefined';
            FormQuestions[1].items[1].disabled = true;
            FormQuestions[1].items[1].options = [];
            FormQuestions[1].items[2].disabled = true;
            FormQuestions[1].items[2].options = [];
            FormQuestions[1].items[3].disabled = true;
            FormQuestions[1].items[3].options = [];
            FormQuestions[1].items[4].disabled = true;
            FormQuestions[1].items[4].options = [];
            cacheIDs.recentlyCalledLanguage=ans[category];
            if(cache['grades'][cacheIDs.recentlyCalledLanguage]!=undefined)
            {
                FormQuestions[1].items[1].disabled = false; 
                FormQuestions[1].items[1].options = cache['grades'][cacheIDs.recentlyCalledLanguage];
                setFormQuestions([...FormQuestions]);
            }
            else
            {
                dispatch({type:'GET_GRADES_BYLANG_REQUESTED',payload:{language:ans[category]}}) 
            }     
            
        }
        else if(category=='gradeID')
        {
           
            ans['subjectID'] = 'undefined';
            FormQuestions[1].items[2].disabled = true;
            FormQuestions[1].items[2].options = [];
            FormQuestions[1].items[3].disabled = true;
            FormQuestions[1].items[3].options = [];
            FormQuestions[1].items[4].disabled = true;
            FormQuestions[1].items[4].options = [];
            cacheIDs.recentlyCalledGrade=ans[category];
            if(cache['subjects'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade]!=undefined )
            {
                FormQuestions[1].items[2].disabled = false; 
                FormQuestions[1].items[2].options = cache['subjects'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade];
                setFormQuestions([...FormQuestions]);
            }
            else
            dispatch({type:'GET_SUBJECTS_BYGRADE_REQUESTED',payload:{gradeId:ans[category]}}) 
        }
        else if(category=='subjectID')
        {
            ans['topicID'] = 'undefined';
            cacheIDs.recentlyCalledSubject = ans[category];
            FormQuestions[1].items[3].disabled = true;
            FormQuestions[1].items[3].options = [];
            FormQuestions[1].items[4].disabled = true;
            FormQuestions[1].items[4].options = [];
            if(cache['topics'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade+cacheIDs.recentlyCalledSubject]!=undefined)
            {
                FormQuestions[1].items[3].disabled = false; 
                FormQuestions[1].items[3].options = cache['topics'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade+cacheIDs.recentlyCalledSubject];
                setFormQuestions([...FormQuestions]);
            }
            else
            dispatch({type:'GET_TOPICS_BY_SUBJECT_REQUESTED',payload:{subjectId:ans[category]}}) 
        }
        else if(category=='topicID' )
        {
            
            ans['subTopicId'] = 'undefined';
            FormQuestions[1].items[4].disabled = true;
            FormQuestions[1].items[4].options = [];
            cacheIDs.recentlyCalledTopic = ans[category];
            if(cache['subtopics'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade+cacheIDs.recentlyCalledSubject+cacheIDs.recentlyCalledTopic]!=undefined)
            {
                FormQuestions[1].items[4].disabled = false; 
                FormQuestions[1].items[4].options = cache['subtopics'][cacheIDs.recentlyCalledLanguage+cacheIDs.recentlyCalledGrade+cacheIDs.recentlyCalledSubject+cacheIDs.recentlyCalledTopic];
                setFormQuestions([...FormQuestions]);
            }
            else
            dispatch({type:'GET_SUBTOPIC_BYTOPIC_REQUESTED',payload:{topicID:ans[category]}}) 
        }
        setAnswers(ans);
        setFormQuestions([...FormQuestions]);
        if(category!='topicID' && category!='subTopicId')
        validateCurrentStepImmediate(ans,currentItemIndex);
        //console.log(cache)
    }

    const validateCurrentStepImmediate = (answers_param,currentItemIndex) => {
        let flag = true;
        
        {
            let currentStepName = FormQuestions[currentStep].items[currentItemIndex].value;
            if(answers_param[currentStepName]==undefined || answers_param[currentStepName]=='undefined' || answers_param[currentStepName]=='')
            {
                validationError[currentItemIndex] = true;
                flag=false;
            }
            else
            {
                validationError[currentItemIndex] = false;
            }
            
        
        }

        setValidationError([...validationError])
        return flag;
    }

    const next = () => {
        setCurrentStep(currentStep=>currentStep+1)
    }

    const prev = () => {
        //setValidationError(false);
        setCurrentStep(currentStep=>currentStep-1)
    }

    const submit = () => {
        console.log(answers,UserLogin);
        answers.testTypeId = Number(answers.testTypeId);
        answers.randomNumber = Number(answers.randomNumber);
        answers.language = Number(answers.language);
        answers.gradeID = Number(answers.gradeID);
        answers.subjectID = Number(answers.subjectID);
        answers.roleId = Number(UserLogin.userRoleID);
        answers.duration = Number(answers.duration);
        answers.passingMark = 50 //Number(answers.passingMark);
        answers.isPublished = true;
        answers.startDate = new Date().toISOString();
        answers.topicID = Number(answers.topicID);
        answers.subTopicId = Number(answers.subTopicId);
        dispatch({type:'SAVE_RANDOM_EXAM_REQUESTED',payload:answers})
    }



    const validateCurrentStep = (answers_param) => {
        
        if(answers_param==undefined) answers_param = answers;
        let flag = false;
        for(let i=0;i<FormQuestions[currentStep].items.length;i++)
        {
            let currentStepName = FormQuestions[currentStep].items[i].value;
            if(currentStepName=='topicID' || currentStepName=='subTopicId')
            {
                continue;
            }
            if(answers_param[currentStepName]==undefined || answers_param[currentStepName]=='undefined' || answers_param[currentStepName]=='')
            {
                validationError[i] = true;
                flag=true;
            }
            else
            {
                validationError[i] = false;
            }
        
        }

        setValidationError([...validationError])
        if(flag) return flag;
        next();
    }

   return (
    <div style={{padding:'10px'}}>
        {
            showPopUp && <Popup title="Random exam generation" returnStateHandler={returnStateHandler}
            body={popUpMsg} />
        }
      <h4 className='text-center'>Generate Random Exam</h4>
        <div key={'FormItem'} className='d-flex justify-content-center'>
                    <Card style={{ width: '70%' , height:'70%', padding:'10px' }} className='border border-5'>
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                            <MultiStepProgressBar numberOfSteps={FormQuestions.length} step={currentStep} />
                        <br></br>
                                {
                                    FormQuestions[currentStep].items?.map((item, index) => {
                                        return (
                                        <Card key={item.value} style={{ width: '90%'}} className='mb-5 p-2 border border-5'>
                                            
                                            <FormItem   answer={answers[FormQuestions[currentStep].items[index].value]} 
                                                currentItemIndex={index} onChange={saveAnswers} key={`${index}_${item.label}`} item={item} />
                                             
                                             <em style={{color:'red'}}>{validationError[index] && 'This step is required to move to next step'}</em>
                                             
                                        </Card>      
                                        )
                                    })
                                }
                       
                        <br></br>
                        <div className="d-flex justify-content-between">
                            { currentStep!=0 && <Button onClick={()=>{prev()}} variant="primary"><i className="fa fa-arrow-circle-left"></i></Button>}
                            { 
                            currentStep<FormQuestions.length-1 ?
                             <Button onClick={()=>{validateCurrentStep();}} variant="primary"><i className="fa fa-arrow-circle-right"></i></Button>
                            :
                             <>
                             <RecaptchaComp verifyHumanCallback={()=>setHuman(true)} />
                             <Button disabled={!Human} onClick={()=>submit()} variant="primary">Submit</Button>  
                             </>
                            }
                        
                        </div>
                    </Card.Body>
                  </Card>
                  
        </div>
    </div>
  )
}

export default MultiStepForm
