import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function getSubTopicByTopicIdFromAPI(action)
{
    let http = `${ApiData.BaseApiLink}/Test/GetSubjectSubTopicsByTopicId/v1/test/getsubjectsubtopicsbytopicid?topicId=`;
    http = http+action?.payload?.topicID;
    return axios.get(http,
    { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
    .then((res)=>{
        console.log('get subTopic API Success')
        return res.data;
    })
    .catch((err)=>{
        console.log('err-getsubTopic API:',err);
    })
}

function* getSubTopicByTopicIdWorkerSaga(action)
{
    try{
            const SubTopics = yield call(getSubTopicByTopicIdFromAPI,action);
            yield put({type:'GET_SUBTOPIC_BYTOPIC',payload:SubTopics.response});
    }
    catch(e)
    {

    }
}

export function* getSubTopicByTopicIdSaga()
{
    yield takeEvery('GET_SUBTOPIC_BYTOPIC_REQUESTED',getSubTopicByTopicIdWorkerSaga)
}