import {all,fork} from 'redux-saga/effects'

import {getAllTestsSaga} from './AllTestsSaga'

import {getQuestionsByTestSaga} from './GetQuestionsByTestSaga'

import {saveAnswerLogAPISaga} from './SaveAnswerLogSaga'

import {saveStudentTestAPISaga} from './SaveStudentTestSaga'

import {LoginSaga} from './LoginSaga'

import {registerTestAPISaga} from './RegisterSaga'

import {saveStudentTestHistoryAPISaga} from './SaveStudentTestHistory'

import {GetStudentTestReportAPISaga} from './GetStudentTestReportSaga'

import {SaveStudentCalculatedResultAPISaga} from './SaveStudentCalculatedResultSaga';

import {GetCalculatedResultsAPISaga} from './GetCalculatedResultsSaga'

import {GetGradewiseSubjectsSaga} from './GetGradewiseSubjectsSaga'

import {ForgotPasswordAPISaga} from './ForgotPasswordSaga'

import {ResetPasswordAPISaga} from './ResetPasswordSaga'

import {ResetStudentPasswordByStudentAPISaga} from './ResetStudentPasswordByStudentSaga'

import {GetStudentAssignedTestAPISaga} from './GetStudentAssignedTestSaga'

import {UpdateStudentTestStatusAPISaga} from './UpdateStudentTestStatusSaga'

import {EmailConfirmationAPISaga} from './EmailConfirmationSaga'

import {RegisterStudentTestAPISaga} from './RegisterStudentSaga'

import {SendEmailAPISaga} from './SendEmailSaga';

import {searchStudentAPISaga} from './SearchStudentSaga';

import {SendStudentTeacherInvitationAPISaga} from './SendStudentTeacherInvitationSaga';

import {StudentTeacherInvitationResponseAPISaga} from './StudentTeacherInvitationResponseSaga'

import {getTestByIdSaga} from './GetTestByIDSaga';

import {saveRandomExamAPISaga} from './SaveRandomExam';

import {getGradesByLangSaga} from './GetGradesByLangSaga'

import {getSubjectsByGradeSaga} from './GetSubjectsByGradeSaga';

import {getTopicsBySubjectSaga} from './GetTopicsBySubjectSaga';

import {getSubTopicByTopicIdSaga} from './GetSubTopicByTopicIdSaga'

export default function* rootSaga(){
    yield all(
        [
            fork(getAllTestsSaga) , fork(getQuestionsByTestSaga) , fork(saveAnswerLogAPISaga) ,
            fork(saveStudentTestAPISaga) , fork(LoginSaga) , fork(registerTestAPISaga) , 
            fork(saveStudentTestHistoryAPISaga) , fork(GetStudentTestReportAPISaga) ,
            fork(SaveStudentCalculatedResultAPISaga) , fork(GetCalculatedResultsAPISaga) ,
            fork(GetGradewiseSubjectsSaga) , fork(ForgotPasswordAPISaga) , fork(ResetPasswordAPISaga) ,
            fork(ResetStudentPasswordByStudentAPISaga) , fork(GetStudentAssignedTestAPISaga) ,
            fork(UpdateStudentTestStatusAPISaga) ,fork(EmailConfirmationAPISaga) , 
            fork(RegisterStudentTestAPISaga) , fork(SendEmailAPISaga) , fork(searchStudentAPISaga) ,
            fork(SendStudentTeacherInvitationAPISaga) , fork(StudentTeacherInvitationResponseAPISaga) ,
            fork(getTestByIdSaga) , fork(saveRandomExamAPISaga) , fork(getGradesByLangSaga) ,
            fork(getSubjectsByGradeSaga) , fork(getTopicsBySubjectSaga) , fork(getSubTopicByTopicIdSaga)
        ]
    )
}