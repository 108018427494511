import {call,put,takeEvery,take} from 'redux-saga/effects';
import axios from 'axios';
import {setCookie,getCookie} from '../../Components/HelperFunctions/CookieSettings'
import ApiData from './ApiData.json';

function getAllTestsFromAPI(action)
{
   
   let payload = action.payload;
   let http = `${ApiData.BaseApiLink}/StudentTest/GetTest`;
   http += '?isEncrypted='+false;
    return axios.post(http,action.payload,
            { headers:{Authorization:'Bearer '+getCookie('domockexamToken'),"Content-Type":"application/json"}})
                .then((res)=>{
                    if(payload.testId==0)
                    {
                        res.data.languageId = payload.languageId;
                        res.data.gradeId = payload.gradeId;
                        res.data.subjectId = payload.subjectId;
                        res.data.SearchData = payload.SearchData;
                    }
                    res.data.paginationQuery = payload.paginationQuery;
                    res.data.isTutorviewOnly = payload.isTutorviewOnly;
                    return res.data;
                })
                .catch((err)=>{
                    console.log('err',err.response);
                    return err;           
                })
    

}

function* getAllTestsWorkerSaga(action)
{
    try{
            const AllTests = yield call(getAllTestsFromAPI,action);
            console.log('getAllTestsWorkerSaga Success');
            yield put({type:'GET_ALL_TESTS',payload:AllTests})
    }
    catch(e)
    {

    }
}

export function* getAllTestsSaga()
{
    yield takeEvery('GET_ALL_TESTS_REQUESTED',getAllTestsWorkerSaga)
}
