import React, { useEffect, useState } from 'react'
import HtmlToReact from 'html-to-react';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
//https://stackoverflow.com/questions/38965088/how-to-put-react-component-inside-html-string
//https://github.com/lakesare/memcode/blob/c049ae23ecabbd502116c440ff8b2d39fd14be88/frontend/services/problemContentToJsx.js

function DynamicComponent(props) {

    const [customElements, setCustomElements] = useState({"droppablecomp": props.DroppableComp})
    const [questionName, setQuestionName] = useState(props.questionName)

        const htmlParser = new Parser(React);
        const processNodeDefinitions = new ProcessNodeDefinitions(React);

        function isValidNode() {
        return true;
        }
        
        // Custom instructions for processing nodes
        const processingInstructions = [
        // Create instruction for custom elements
        {
            shouldProcessNode: (node) => {
            // Process the node if it matches a custom element
            //return (node.name && customElements[node.name]);
            return (node.name == 'droppablecomp' );
            },
            processNode: (node) => {
            let CustomElement = customElements[node.name];
            return props.DroppableComp(node?.attribs?.ind);
            return (<CustomElement  ind={node?.attribs?.ind} />);
            }
        },
        // Default processing
        {
            shouldProcessNode: (node) => true,
            processNode: processNodeDefinitions.processDefaultNode
        }
        ];

        function renderDynamicData()
        {
            return htmlParser.parseWithInstructions(questionName, 
                (() => true), processingInstructions)
        }

  return (
    <div>
        {renderDynamicData()}
    </div>
  )
}

export default DynamicComponent